import React from 'react';

function Vaccination() {
    const vaccinations = [
        {
            treatmentDate: "24 October 2024",
            cost: 250,
            recordedBy: "Dr. Pashupathi",
            remarks: "Completely Fit",
        },

        // Add more vaccination records as needed
    ];

    return (
        <div className="basic-info-container">
            <div className="flex justify-between items-center">
                <p className="text-[#16A34A] font-plus-jakarta-sans text-sm font-semibold leading-6">
                    Vaccination
                </p>
            </div>
            <div className="text-[#726C6C] font-plus-jakarta-sans text-xs font-semibold  flex flex-row items-center">
                <div>
                    <p><strong>Treatment Date</strong></p>
                    <p><strong>Cost In Rupees</strong></p>
                    <p><strong>Recorded By</strong></p>
                    <p><strong>Doctor Remarks</strong></p>
                </div>
                {vaccinations.map((vaccination, index) => (
                    <div key={index} className="ml-10 text-[#0A0B0A]">
                        <p>{vaccination.treatmentDate}</p>
                        <p> ₹ {vaccination.cost}</p>
                        <p>{vaccination.recordedBy}</p>
                        <p className="italic font-normal">{vaccination.remarks}</p>
                    </div>
                ))}
            </div>
            <div className="flex justify-between items-center mt-4">
                <p className="text-[#16A34A] font-plus-jakarta-sans text-sm font-semibold leading-6">
                    Foot And Mouth Disease
                </p>
            </div>
            <div className="text-[#726C6C] font-plus-jakarta-sans text-xs font-semibold  flex flex-row items-center">
                <div>
                    <p><strong>Treatment Date</strong></p>
                    <p><strong>Cost In Rupees</strong></p>
                    <p><strong>Recorded By</strong></p>
                    <p><strong>Doctor Remarks</strong></p>
                </div>
                {vaccinations.map((vaccination, index) => (
                    <div key={index} className="ml-10 text-[#0A0B0A]">
                        <p>{vaccination.treatmentDate}</p>
                        <p> ₹ {vaccination.cost}</p>
                        <p>{vaccination.recordedBy}</p>
                        <p className="italic font-normal">{vaccination.remarks}</p>
                    </div>
                ))}
            </div>
            <div className="flex justify-between items-center mt-4">
                <p className="text-[#16A34A] font-plus-jakarta-sans text-sm font-semibold leading-6">
                    Vaccination
                </p>
            </div>
            <div className="text-[#726C6C] font-plus-jakarta-sans text-xs font-semibold  flex flex-row items-center">
                <div>
                    <p><strong>Treatment Date</strong></p>
                    <p><strong>Cost In Rupees</strong></p>
                    <p><strong>Recorded By</strong></p>
                    <p><strong>Doctor Remarks</strong></p>
                </div>
                {vaccinations.map((vaccination, index) => (
                    <div key={index} className="ml-10 text-[#0A0B0A]">
                        <p>{vaccination.treatmentDate}</p>
                        <p> ₹ {vaccination.cost}</p>
                        <p>{vaccination.recordedBy}</p>
                        <p className="italic font-normal">{vaccination.remarks}</p>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Vaccination;
