import React from 'react'
import CommonButton from '../../Common/Button';

function DietPlan() {
  return (
    <div className="basic-info-container">
    <div className="flex justify-between items-center">
        <p className="text-[#16A34A] font-plus-jakarta-sans text-sm  font-semibold leading-6">
        Diet Plan 
        </p>
       
    </div>
    <div className="text-[#726C6C] font-plus-jakarta-sans text-xs font-semibold  flex flex-row items-center ">
        <div>
            <p><strong>Cattle ID</strong></p>
            <p><strong>Cattle Weight</strong></p>
            <p><strong>Feed Group</strong></p>
            <CommonButton title='Edit Feed' style={{ fontSize: '10px', borderRadius: 10, backgroundColor: "#ffff", color: "#16A34A", border: "1px #16A34A solid" }}/>
        </div>
        <div className="ml-10 text-[#0A0B0A]">
            <p>1856</p>
            <p>550 kg</p>
            <p>Q1 TMR</p>
            <CommonButton title='Feed Cattle' style={{ fontSize: '10px', borderRadius: 10, backgroundColor: "#16A34A", color: "#ffff", border: "none" }}/> 
        </div>
    </div>
    
</div>
);
}

export default DietPlan