import React, { useEffect, useState } from 'react'
import FlexCol from '../Common/FlexCol';
import Heading from '../Common/Heading';
import Dropdownbtn from '../Common/Dropdownbtn';
import SearchBox from '../Common/SearchBox';
import { Nav, NavItem, NavLink } from 'reactstrap';
import ReactTable from '../Common/Table';
import CommonButton from '../Common/Button';
import cowicon from '../../assets/Images/Cow icon.png'
import AddPregnancy from '../Drawer/AddPregnancy';
import RecordDeliveryForm from '../Drawer/RecordDeliveryForm';
import RecordDelivery from '../Drawer/RecordDelivery';
import API, { action } from '../../Api';

function DeliveryReport() {
 const[report,setReport]=useState(false)
    const [tab, setTab] = useState("1");
    const pillsToggle = (tabs) => {
        if (tab !== tabs) {
            setTab(tabs);
        }
    };
const handleclick=()=>setReport(!report)


    const headings = [

        {
            id: 1,
            title: "Sl No",
            value: "SlNo",

        },
        {
            id: 2,
            title: "Cattle ID",
            value: "CattleID",
            icon: cowicon


        },
        {
            id: 3,
            title: "Insemination Type",
            value: "InseminationType",

        },


        {
            id: 4,
            title: "Severity",
            value: "Severity",


        },
        {
            id: 5,
            title: "Expected Delivery Date",
            value: "ExpectedDeliveryDate",


        },
        {
            id: 6,
            title: "Action",
            value: "action",


        },
    
    ]


    const data = [
        {
            SlNo: "01",
            CattleID: "Cattle No.1856",
            LactationNumber: "2",
            Severity: "Need Care",
            ExpectedDeliveryDate: "11-10-2024",
            
            action: "Take Delivery",
        },
        {
            SlNo: "02",
            CattleID: "Cattle No.1987",
            LactationNumber: "3",
            Severity: "Normal",
            ExpectedDeliveryDate: "11-10-2024",
            action: "Take Delivery",
        },
        {
            SlNo: "03",
            CattleID: "Cattle No.1745",
            LactationNumber: "1",
            Severity: "Critical",
            ExpectedDeliveryDate: "11-10-2024",
            action: "Take Delivery",
        },
        {
            SlNo: "04",
            CattleID: "Cattle No.1623",
            LactationNumber: "4",
            Severity: "Normal",
            ExpectedDeliveryDate: "11-10-2024",
            action: "Take Delivery",
        },
        {
            SlNo: "05",
            CattleID: "Cattle No.1456",
            LactationNumber: "2",
            Severity: "Need Care",
            ExpectedDeliveryDate: "11-10-2024",
            action: "Take Delivery",
        },
        {
            SlNo: "06",
            CattleID: "Cattle No.1543",
            LactationNumber: "3",
            Severity: "Normal",
            ExpectedDeliveryDate: "11-10-2024",
            action: "Take Delivery",
        },
        {
            SlNo: "07",
            CattleID: "Cattle No.1678",
            LactationNumber: "1",
            Severity: "Need Care",
            ExpectedDeliveryDate: "11-10-2024",
            action: "Take Delivery",
        },
        {
            SlNo: "08",
            CattleID: "Cattle No.1234",
            LactationNumber: "4",
            Severity: "Normal",
            ExpectedDeliveryDate: "11-10-2024",
            action: "Take Delivery",
        },
        {
            SlNo: "09",
            CattleID: "Cattle No.1098",
            LactationNumber: "3",
            Severity: "Critical",
            ExpectedDeliveryDate: "11-10-2024",
            action: "Take Delivery",
        },
        {
            SlNo: "10",
            CattleID: "Cattle No.1999",
            LactationNumber: "2",
            Severity: "Normal",
            ExpectedDeliveryDate: "11-10-2024",
            action: "Take Delivery",
        },
        {
            SlNo: "11",
            CattleID: "Cattle No.1321",
            LactationNumber: "1",
            Severity: "Normal",
            ExpectedDeliveryDate: "11-10-2024",
            action: "Take Delivery",
        },
        {
            SlNo: "12",
            CattleID: "Cattle No.1156",
            LactationNumber: "4",
            Severity: "Critical",
            ExpectedDeliveryDate: "11-10-2024",
            action: "Take Delivery",
        },
        {
            SlNo: "13",
            CattleID: "Cattle No.1425",
            LactationNumber: "3",
            Severity: "Need Care",
            ExpectedDeliveryDate: "11-10-2024",
            action: "Take Delivery",
        },
        {
            SlNo: "14",
            CattleID: "Cattle No.1867",
            LactationNumber: "2",
            Severity: "Normal",
            ExpectedDeliveryDate: "11-10-2024",
            action: "View Report",
        },
        {
            SlNo: "15",
            CattleID: "Cattle No.1789",
            InseminationType: "1",
            Severity: "Normal",
            ExpectedDeliveryDate: "11-10-2024",
            action: "View Report",
        },
    ];

    const [showPregnancyReport, setShowPregnancyRepor] = useState(false);
    const [delivery, setDelivery] = useState(false);
    const [deliveryList,setDeliveryList]= useState([])
    const handleButtonClick = () => setShowPregnancyRepor(!showPregnancyReport)
    const ButtonClick = () => setDelivery(!delivery)



    const getDeliveryReport = async () => {
        const result = await action(API.GET_INSEMINATION, {
            type :1,
            searchKey :"",
            page :1,
            pageCount : 10
        });
        console.log(result.response.data, "get delivery list")
        // setEmployeeLeave(result.result);
        setDeliveryList(result.response.data)
        const formattedData = result.response.data.map((item, index) => ({
            SlNo: (index + 1).toString().padStart(2, '0'), // Format SlNo
          
         
            CattleID: item.Cattle.cattle_id,
            InseminationType: item.insemination_type,
            InseminationDate: item.insemination_date,
            
            status : item.status,
            ExpectedPregnancy: item.expected_pregnancy,
           
            action: item.status === 'pregnant' ? 'Take Delivery' :  'Diagnose'
        }))

        setDeliveryList(formattedData);
    };
    useEffect(() => {
        getDeliveryReport()
        console.log(deliveryList, 'deliveryList');

    }, [])




  return (
    <div style={{ backgroundColor: "#FAFAFA" }}>
            <FlexCol>
                <div className="flex flex-col sm:flex-row justify-between">
                    <div className='d-flex space-x-2'>
                        <Heading title='Delivery Report' />
                        <Dropdownbtn title='Today' style={{backgroundColor:"#FAFAFA",color:"#5A5555",border: '1px #CCCBCB solid'}} />
                    </div>
                    <div className="d-flex space-x-5">
                        <SearchBox placeholder='Search Cattle' className='bg-[#F8F9FB]' />
                        <CommonButton title="Add Pregnancy" style={{backgroundColor:"none",color:"#16A34A",border: "1px solid #16A34A"}} onClick={handleButtonClick} />
                <CommonButton title="Record Delivery" onClick={ButtonClick} />

                    </div>
                </div>
                {/* <Cards />
                <Row className="g-3">
                    <Col md={7} >
                        <Graph title='Sick Cattle Statistics' />

                    </Col>
                    <Col md={5} >
                        <PieChart title="Sick Cattle Distribution" />

                    </Col>

                </Row> */}
                <Nav pills className="nav-success ">
                    {["All", "Pending", "In Progress", "Completed"].map((tabs, index) => (
                        <NavItem key={index}>
                            <NavLink
                                style={{
                                    cursor: "pointer",
                                    color: "#0A0B0A",
                                    backgroundColor: tab === String(index + 1) ? '#E3E3E3' : 'transparent',
                                    fontFamily: "Plus Jakarta Sans",
                                    fontSize: "12px",
                                    fontWeight: 600,
                                    lineHeight: "120%",
                                    textDecoration: 'none' // Optional underline for active tab
                                }}
                                className={tab === String(index + 1) ? "active" : ""}
                                onClick={() => pillsToggle(String(index + 1))}
                            >
                                {tabs}
                            </NavLink>
                        </NavItem>
                    ))}
                </Nav>
                {tab === "1" && <ReactTable headings={headings} data={deliveryList} headingcolor="#D9D9D9" handleAction={handleclick} />}
            </FlexCol>
            <AddPregnancy isOpen={showPregnancyReport} toggle={handleButtonClick}/>
            <RecordDeliveryForm isOpen={delivery} toggle={ButtonClick}/>
            <RecordDelivery isOpen={report} toggle={handleclick}/>


        </div>
  )
}

export default DeliveryReport