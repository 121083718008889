import React, { useState, useEffect } from 'react';
import { Card, CardBody, CardTitle, Row, Col } from 'reactstrap';
import cowIcon from "../../assets/Images/Cow icon.png"; // Assuming you still want to use the cow icon for now
import API, { action } from '../../Api';

const UserCard = ({ name, icon, isSelected, onSelect }) => (
    <Row
        className={`align-items-center py-2`}
        onClick={onSelect}
        style={{ cursor: 'pointer' }}
    >
        <Col xs="auto">
            <img src={icon} alt="user icon" style={{ width: '24px', height: '24px', marginLeft: "8px" }} />
        </Col>
        <Col>
            <CardTitle style={{ margin: 0, padding: 0, fontWeight: isSelected ? 600 : 400 }}>
                {name}
            </CardTitle>
        </Col>
    </Row>
);

const CattleDashboardSideBar = ({ onTabSelect, onCattleSelect, cattleList }) => {
    const [selectedUser, setSelectedUser] = useState(null);
    const [visibleUsers, setVisibleUsers] = useState(10);
    const [users, setUsers] = useState([]);

    const handleSelect = (name, cattleData) => {
        setSelectedUser(name);
        onCattleSelect && onCattleSelect(cattleData);
        onTabSelect && onTabSelect(name);
    };

    const handleScroll = (e) => {
        const { scrollTop, scrollHeight, clientHeight } = e.target;
        if (scrollTop + clientHeight >= scrollHeight - 5) {
            setVisibleUsers((prevVisible) => Math.min(prevVisible + 5, users.length));
        }
    };

    useEffect(() => {
        const formattedUsers = cattleList.map(cattle => ({
            name: `Cattle No. ${cattle.cattle_id.toString().charAt(0).toUpperCase()}${cattle.cattle_id.toString().slice(1)}`,
            icon: cowIcon,
            data: cattle,
        }));
        setUsers(formattedUsers);
    }, [cattleList]);

    // Select the first user after users are set
    useEffect(() => {
        if (users.length > 0 && !selectedUser) {
            const firstUser = users[0];
            setSelectedUser(firstUser.name);
            if (onCattleSelect) onCattleSelect(firstUser.data);
            if (onTabSelect) onTabSelect(firstUser.name);
        }
    }, [users, onCattleSelect, onTabSelect, selectedUser,cattleList]);



    


    return (
        <div className="container p-0">
            <Card
                style={{
                    borderRadius: '12px',
                    border: '1px solid #E3E3E3',
                    background: '#FFF',
                    width: '100%',
                    maxWidth: '266px',
                }}
            >
                <CardBody
                    onScroll={handleScroll}
                    style={{
                        maxHeight: '540px',
                        overflowY: 'auto',
                        height: '540px'
                    }}
                >
                    {users.slice(0, visibleUsers).map((user, index) => (
                        <div
                            key={index}
                            className="user-card"
                            style={{
                                color: selectedUser === user.name ? '#FDBF26' : '#656565',
                                fontSize: '12px',
                                fontWeight: 400,
                                fontFamily: 'Plus Jakarta Sans',
                                backgroundColor: selectedUser === user.name ? "rgba(254, 234, 183, 0.50)" : 'transparent',
                                borderRadius: '8px',
                            }}
                        >
                            <UserCard
                                name={user.name}
                                icon={user.icon}
                                isSelected={selectedUser === user.name}
                                onSelect={() => handleSelect(user.name, user.data)} // Pass the full cattle data
                            />
                            {index < visibleUsers - 1 && (
                                <hr
                                    style={{
                                        margin: '5px 0',
                                        border: selectedUser === user.name ? '1px solid rgba(254, 234, 183, 0.50)' : '1px solid #E3E3E3',
                                    }}
                                />
                            )}
                        </div>
                    ))}
                </CardBody>
            </Card>
        </div>
    );
};

export default CattleDashboardSideBar;
