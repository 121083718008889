import React from 'react'
import { Card, CardBody, Col, DropdownToggle, UncontrolledDropdown } from 'reactstrap'
import SimpleBar from 'simplebar-react'
import cow from '../../assets/Images/Cow icon.png'
import { GoBell, GoPencil } from 'react-icons/go';
import { PiDotsThreeOutlineVerticalFill } from 'react-icons/pi';

function BreedingList({title,text,bgcolor}) {
    const pendingvaccinations = [
        {
          id: 1,
          image: cow,
          text: "Cattle No. 1856",
          date: "24 Sep 2024",
        },
        {
          id: 2,
          image: cow,
          text: "Cattle No. 1856",
          date: "20 Sep 2021",
        },
        {
          id: 3,
          image: cow,
          text: "Cattle No. 1856",
          date: "24 Sep 2021",
        },
        {
          id: 4,
          image: cow,
          text: "Cattle No. 1856",
          date: "27 Sep 2021",
        },
       
      ];
  return (
    <div className="w-full h-full p-3 bg-[#FAFAFA] rounded-xl border border-[#E3E3E3] flex flex-col items-start gap-2.5">
            <div className="self-stretch h-full flex flex-col justify-start items-start gap-4">
                <div className="self-stretch flex justify-between items-center">
                    <div className="text-[#414D55] text-sm font-bold leading-[21.6px] font-plus-jakarta-sans">{title}s</div>
                    <div className="text-[#898483] text-xs font-normal leading-[16.8px] font-plus-jakarta-sans">See All</div>
                </div>
                <div className="self-stretch h-full flex flex-col justify-start items-start gap-3">
                    {Array.from({ length: 4 }, (_, index) => (
                        <div key={index} className="self-stretch p-2 bg-white rounded-lg border border-[#E3E3E3] flex justify-between items-center">
                            <div className="self-stretch flex justify-start items-start gap-2">
                                <div className="relative w-10 h-10">
                                    <div style={{ backgroundColor: bgcolor }} className="absolute w-10 h-10  rounded-md"/>
                                    <img
                                        className="absolute w-9 h-9 rounded-md top-0 left-0.5"
                                        src={cow}
                                    //   alt={item.text}
                                    />
                                </div>
                                <div className="flex flex-col justify-start items-start gap-1">
                                    <div className="self-stretch text-[#0A0B0A] text-sm font-bold leading-[19.2px] font-plus-jakarta-sans">Cattle No. 1856</div>
                                    <div className="self-stretch text-[#898483] text-xs font-normal leading-[16.8px] font-plus-jakarta-sans">{text}</div>
                                </div>
                            </div>
                  <div className="flex gap-2">
                      <GoBell  className="text-[#0EA5E9]" />
                      <GoPencil   className="text-[#B5B3B3]" />
                      <PiDotsThreeOutlineVerticalFill  className="text-[#B5B3B3]" />
                    </div>
                </div>
              ))}
           </div>
            </div>
        </div>
  )
}

export default BreedingList