import React from 'react';
import { Card, CardBody } from 'reactstrap';
import CommonButton from '../../Common/Button';

function Breeding() {
  const colorCombinations = [
    { text: '#C98F02', background: '#FEEAB780' },
    { text: '#0A74A3', background: '#ACE2FA80' },
    { text: '#21055E', background: '#E6DBFD' },
    { text: '#0B4F24', background: '#DBFAE780' },
  ];

  const cardData = [
    { days: 18, title: 'Insemination', date: '06 Sep 2024', description: 'Artificial insemination' },
    { days: 20, title: 'Insemination', date: '08 Sep 2024', description: 'Natural insemination' },
    { days: 15, title: 'Insemination', date: '10 Sep 2024', description: 'Frozen semen' },
    { days: 14, title: 'Insemination', date: '06 Sep 2024', description: 'Artificial insemination' },
    { days: 10, title: 'Insemination', date: '08 Sep 2024', description: 'Natural insemination' },
    { days: 16, title: 'Insemination', date: '10 Sep 2024', description: 'Frozen semen' },
    { days: 14, title: 'Insemination', date: '06 Sep 2024', description: 'Artificial insemination' },
    { days: 10, title: 'Insemination', date: '08 Sep 2024', description: 'Natural insemination' },
    { days: 16, title: 'Insemination', date: '10 Sep 2024', description: 'Frozen semen' },
    // Add more cards as needed
  ];

  return (
    <div className="basic-info-container">
      <div className="flex justify-between">
        <p className="text-[#16A34A] font-plus-jakarta-sans text-lg font-semibold leading-6">
          Breeding
        </p>
        <CommonButton title='Add New State' style={{backgroundColor:"#E3E3E3",color:"#2B2928", fontSize:"10px", fontFamily:"Plus Jakarta Sans" }}/>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-4">
        {cardData.map((data, index) => {
          const { background, text } = colorCombinations[index % colorCombinations.length];
          return (
            <Card key={index} className="border border-gray-300 rounded-lg w-full max-w-xs">
              <CardBody className="flex space-x-4 p-3">
                {/* Left side small card */}
                <div className={`rounded-md flex flex-col justify-center items-center p-2 gap-4`} style={{ backgroundColor: background }}>
                  <div className="text-lg md:text-sm font-bold text-center" style={{ color: text }}>
                    {data.days} <br /> Days
                  </div>
                </div>

                {/* Right side text */}
                <div className="flex flex-col justify-center items-start gap-1">
                  <div className="text-gray-900 text-sm font-bold">
                    {data.title}
                  </div>
                  <div className="text-gray-600 text-xs">
                    {data.date}
                  </div>
                  <div className="text-gray-600 text-xs">
                    {data.description}
                  </div>
                </div>
              </CardBody>
            </Card>
          );
        })}
      </div>
    </div>
  );
}

export default Breeding;
