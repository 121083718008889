import React, { useState, useEffect } from "react";
import { Card, CardBody, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import RevenueCharts from "./RevenueCharts";

function Graph({ title = "", }) {
    const [chartData, setChartData] = useState([]);
    const [revenueData, setRevenueData] = useState([]);
    const [previousData, setPreviousData] = useState([]);
    const [chartCategories, setChartCategories] = useState([]);

    const getRevenueChartsData = async (period) => {
        await new Promise((resolve) => setTimeout(resolve, 1000));

        const previousData = [
            { name: "January", value: 80 },
            { name: "February", value: 110 },
            { name: "March", value: 20 },
            { name: "April", value: 40 },
            { name: "May", value: 35 },
            { name: "June", value: 55 },
            { name: "July", value: 25 },
            { name: "August", value: 65 },
            { name: "September", value: 75 },
            { name: "October", value: 115 },
            { name: "November", value: 45 },
            { name: "December", value: 30 },
        ];

        switch (period) {
            case "all":
                return {
                    current: [
                        { name: "January", value: 90 },
                        { name: "February", value: 120 },
                        { name: "March", value: 30 },
                        { name: "April", value: 50 },
                        { name: "May", value: 40 },
                        { name: "June", value: 60 },
                        { name: "July", value: 30 },
                        { name: "August", value: 70 },
                        { name: "September", value: 80 },
                        { name: "October", value: 120 },
                        { name: "November", value: 50 },
                        { name: "December", value: 40 },
                    ],
                    previous: previousData,
                };
            case "month":
                return {
                    current: [
                        { name: "Week 1", value: 2000 },
                        { name: "Week 2", value: 3000 },
                        { name: "Week 3", value: 2500 },
                        { name: "Week 4", value: 3500 },
                    ],
                    previous: [
                        { name: "Week 1", value: 1800 },
                        { name: "Week 2", value: 2700 },
                        { name: "Week 3", value: 2300 },
                        { name: "Week 4", value: 3200 },
                    ],
                };
            // Add similar blocks for "halfyear" and "year"
            default:
                return { current: [], previous: [] };
        }
    };

    const fetchRevenueData = async (period) => {
        const { current, previous } = await getRevenueChartsData(period);
        setPreviousData(previous); // Update previous data
        setRevenueData(current); // Update current revenue data
    };

    useEffect(() => {
        fetchRevenueData("all"); // Fetch initial data
    }, []);

    useEffect(() => {
        if (revenueData.length > 0 || previousData.length > 0) {
            const formattedData = [
                {
                    name: 'Current Revenue',
                    type: 'bar',
                    data: revenueData.map(item => item.value),
                },
                {
                    name: 'Previous Revenue',
                    type: 'line',
                    data: previousData.map(item => item.value),
                },
            ];

            const categories = [...new Set([...previousData, ...revenueData].map(item => item.name))]; // Unique categories

            setChartData(formattedData);
            setChartCategories(categories);
        }
    }, [revenueData, previousData]);

    const onChangeChartPeriod = (pType) => {
        fetchRevenueData(pType);
    };

    return (
        <React.Fragment>
            <div className="2xl:p-6 p-2  bg-white rounded-xl border-1 border-[#E3E3E3]">
                <CardBody className="p-0 pb-2">
                    <div className="w-100 ">
                        <div className="border-0 align-items-center d-flex ml-5 ">
                            <h4 className="card-title mb-0 flex-grow-1 mt-2 text-[14px] 2xl:text-[18px]" style={{
                                color: "#495057",
                                fontFamily: "Plus Jakarta Sans",
                               
                                fontWeight: 700,
                            }}>{title}</h4>
                            <UncontrolledDropdown className="card-header-dropdown mr-5 mt-2" direction='start'>
                                <DropdownToggle tag="a" className="text-reset dropdown-btn text-[14px] 2xl:text-[18px]" style={{ textDecoration: 'none' }} role="button">
                                    <span className="text-muted" style={{
                                        color: "#898483",
                                        fontFamily: "Plus Jakarta Sans",
                                       
                                        fontWeight: 400,
                                    }}>Report<i className="mdi mdi-chevron-down ms-1"></i></span>
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu dropdown-menu-end">
                                    <DropdownItem>Download Report</DropdownItem>
                                    <DropdownItem>Export</DropdownItem>
                                    <DropdownItem>Import</DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </div>
                        <div dir="ltr" className="mt-4">
                            <RevenueCharts series={chartData} dataColors='["--vz-secondary", "--vz-primary", "--vz-primary-rgb, 0.50"]' categories={chartCategories} />
                        </div>
                    </div>
                </CardBody>
         </div>
        </React.Fragment>
    );
}

export default Graph;
