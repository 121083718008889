import React from "react";
import { BarChart, Bar, XAxis, YAxis, ResponsiveContainer } from "recharts";
import { Card, CardBody } from 'reactstrap';
import barchart from "../../assets/Images/Frame.svg";
import ci from "../../assets/Images/ci_expand.svg";

const data = [
    { day: "SUN", litre: 176 },
    { day: "MON", litre: 156 },
    { day: "TUE", litre: 136 },
    { day: "WED", litre: 116 },
    { day: "THU", litre: 132 },
    { day: "FRI", litre: 156 },
    { day: "SAT", litre: 224 },
];

const CommonGraph = ({ title, img, barColor = "#16A34A", slopeColor = "#16A34A", gradientColors = ["rgba(94.67, 212, 84, 0.55)", "rgba(255, 255, 255, 0)"] }) => {
    return (
        <div className="  bg-white rounded-xl border border-gray-300 2xl:p-6 p-3">
            <CardBody>
                <div className="flex justify-between items-center mb-4">
                    <div className="flex items-center gap-2">
                        <div className="w-6 h-6 rounded mb-3">
                            <img src={img} alt="Chart Icon" />
                        </div>
                        <h2 className="text-sm font-bold text-[#080808] font-plus-jakarta-sans">{title}</h2>
                    </div>
                    <div className="bg-white rounded-md border border-gray-300 flex items-center w-14 h-7">
                        <img src={ci} className='w-7 h-5' alt="Expand Icon" />
                        <p className='ml-1 mt-1'>...</p>
                    </div>
                </div>

                <div className="relative h-[350px]">
                    <ResponsiveContainer width="100%" height="100%">
                        <BarChart data={data} barSize={128} barGap={2}>
                            <XAxis dataKey="day" axisLine={false} tickLine={false} tick={<CustomizedTick />} />
                            <YAxis hide />
                            <Bar dataKey="litre" shape={<CustomBar barColor={barColor} slopeColor={slopeColor} gradientColors={gradientColors} />} label={<CustomLabel />} />
                        </BarChart>
                    </ResponsiveContainer>
                </div>
            </CardBody>
        </div>
    );
};

// Custom Tick Component
const CustomizedTick = ({ x, y, payload }) => {
    return (
        <text
            x={x}
            y={y + 15}
            fill="#6B7280"
            textAnchor="middle"
            style={{ fontSize: "10px", fontWeight: "bold" }}
        >
            {payload.value}
        </text>
    );
};

// Custom Bar Component
const CustomBar = ({ x, y, width, height, index, value, barColor, slopeColor, gradientColors }) => {
    const currentDayIndex = new Date().getDay();
    const dataDayIndex = index;
    const fill = dataDayIndex === currentDayIndex ? barColor : `url(#gradient${index})`;
    const slopeHeight = 20;
    const borderRadius = 0;
    const borderRadius2 = 10;
    const borderHeight = 850;
    const topLineY1 = y; // Current bar's top
    const slope = 20; // The desired slope value

    return (
        <g>
            <defs>
                <linearGradient id={`gradient${index}`} x1="0" y1="0" x2="0" y2="1">
                    <stop offset="0%" stopColor={gradientColors[0]} />
                    <stop offset="100%" stopColor={gradientColors[1]} />
                </linearGradient>
            </defs>

            <path
                d={`
          M ${x + borderRadius},${y}
          L ${x + width - borderRadius},${y + slopeHeight}
          Q ${x + width},${y + slopeHeight} ${x + width},${y + slopeHeight + borderRadius}
          L ${x + width},${y + height - borderRadius}
          Q ${x + width},${y + height} ${x + width - borderRadius},${y + height}
          L ${x + borderRadius},${y + height}
          Q ${x},${y + height} ${x},${y + height - borderRadius}
          L ${x},${y + borderRadius}
          Q ${x},${y} ${x + borderRadius},${y}
        `}
                fill={fill}
                strokeWidth={1}
            />

            <line
                x1={x}
                y1={topLineY1}
                x2={x + width}
                y2={topLineY1 + slope} // Apply slope to create a descending line
                stroke={slopeColor}
                strokeWidth={1}
            />

            <rect
                x={x}
                width={width}
                height={borderHeight}
                fill="none"
                stroke="#E0E0E0"
                strokeWidth={1}
                rx={borderRadius2}
                ry={borderRadius2}
            />
        </g>
    );
};

// Custom Label Component
const CustomLabel = (props) => {
    const { x, y, width, value } = props;
    return (
        <text
            x={x + width / 2}
            y={y - 20}
            fill="#6B7280"
            textAnchor="middle"
            dominantBaseline="middle"
            style={{ fontSize: "12px" }}
        >
            {value}
        </text>
    );
};

export default CommonGraph;
