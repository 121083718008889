import React, { useState } from 'react'
import FlexCol from '../Common/FlexCol'
import Heading from '../Common/Heading'
import Dropdownbtn from '../Common/Dropdownbtn'
import SearchBox from '../Common/SearchBox'
import Filterbtn from '../Common/Filterbtn'
import { Nav, NavItem, NavLink } from 'reactstrap'
import ReactTable from '../Common/Table'
import cowicon from "../../assets/Images/Cow icon.png"
import CommonButton from '../Common/Button'
import MultipleMilkEntries from '../Drawer/MultipleMilkEntries'
function MilkingStatistics() {
    const [tab, setTab] = useState("1");
    const pillsToggle = (tabs) => {
        if (tab !== tabs) {
            setTab(tabs);
        }
    };

    const [showMilkingStats, setShowMilkingStats] = useState(false);
    const [milk, setMilk] = useState(false)

    const handleButtonClick = () => setShowMilkingStats(!showMilkingStats)
    const handleclick = () => setMilk(!milk)

    const headings = [

        {
            id: 1,
            title: "Sl No",
            value: "SlNo",

        },
        {
            id: 2,
            title: "Cattle ID",
            value: "CattleID",
            icon: cowicon


        },
        {
            id: 3,
            title: "Lactation Number",
            value: "LactationNumber",

        },


        {
            id: 4,
            title: "Last Calving date",
            value: "LastCalvingdate",


        },
        {
            id: 5,
            title: "Heat Type",
            value: "HeatType",


        },
        {
            id: 6,
            title: "Heat Date",
            value: "HeatDate",


        },
        {
            id: 7,
            title: "Action",
            value: "action",


        },



    ]


    const data = [
        {
            SlNo: "01",
            CattleID: "Cattle No.1856",
            LactationNumber: "2",
            LastCalvingdate: "06-09-2024",
            HeatType: "Natural",
            HeatDate: "11-10-2024",
            action: "Add Milking",
        },
        {
            SlNo: "02",
            CattleID: "Cattle No.1987",
            LactationNumber: "3",
            LastCalvingdate: "02-08-2024",
            HeatType: "Artificial",
            HeatDate: "12-10-2024",
            action: "View Milk report",
        },
        {
            SlNo: "03",
            CattleID: "Cattle No.1745",
            LactationNumber: "1",
            LastCalvingdate: "06-09-2024",
            HeatType: "Natural",
            HeatDate: "11-10-2024",
            action: "Add Milking",
        },
        {
            SlNo: "04",
            CattleID: "Cattle No.1623",
            LactationNumber: "4",
            LastCalvingdate: "06-09-2024",
            HeatType: "Natural",
            HeatDate: "11-10-2024",
            action: "Add Milking",
        },
        {
            SlNo: "05",
            CattleID: "Cattle No.1456",
            LactationNumber: "2",
            LastCalvingdate: "06-09-2024",
            HeatType: "Natural",
            HeatDate: "11-10-2024",
            action: "Add Milking",
        },
        {
            SlNo: "06",
            CattleID: "Cattle No.1543",
            LactationNumber: "3",
            LastCalvingdate: "06-09-2024",
            HeatType: "Natural",
            HeatDate: "11-10-2024",
            action: "Add Milking",
        },
        {
            SlNo: "07",
            CattleID: "Cattle No.1678",
            LactationNumber: "1",
            LastCalvingdate: "06-09-2024",
            HeatType: "Natural",
            HeatDate: "11-10-2024",
            action: "Add Milking",
        },
        {
            SlNo: "08",
            CattleID: "Cattle No.1234",
            LactationNumber: "4",
            LastCalvingdate: "06-09-2024",
            HeatType: "Artificial",
            HeatDate: "11-10-2024",
            action: "View Milk report",
        },
        {
            SlNo: "09",
            CattleID: "Cattle No.1098",
            LactationNumber: "3",
            LastCalvingdate: "06-09-2024",
            HeatType: "Natural",
            HeatDate: "11-10-2024",
            action: "Add Milking",
        },
        {
            SlNo: "10",
            CattleID: "Cattle No.1999",
            LactationNumber: "2",
            LastCalvingdate: "06-09-2024",
            HeatType: "Natural",
            HeatDate: "11-10-2024",
            action: "Add Milking",
        },
        {
            SlNo: "11",
            CattleID: "Cattle No.1321",
            LactationNumber: "1",
            LastCalvingdate: "06-09-2024",
            HeatType: "Natural",
            HeatDate: "11-10-2024",
            action: "Add Milking",
        },
        {
            SlNo: "12",
            CattleID: "Cattle No.1156",
            LactationNumber: "4",
            LastCalvingdate: "06-09-2024",
            HeatType: "Natural",
            HeatDate: "11-10-2024",
            action: "View Milk report",
        },
        {
            SlNo: "13",
            CattleID: "Cattle No.1425",
            LactationNumber: "3",
            LastCalvingdate: "06-09-2024",
            HeatType: "Artificial",
            HeatDate: "11-10-2024",
            action: "Add Milking",
        },
        {
            SlNo: "14",
            CattleID: "Cattle No.1867",
            LactationNumber: "2",
            LastCalvingdate: "06-09-2024",
            HeatType: "Natural",
            HeatDate: "11-10-2024",
            action: "Add Milking",
        },
        {
            SlNo: "15",
            CattleID: "Cattle No.1789",
            LactationNumber: "1",
            LastCalvingdate: "06-09-2024",
            HeatType: "Artificial",
            HeatDate: "11-10-2024",
            action: "View Milk report",
        },
    ];
    return (
        <div style={{ backgroundColor: "#FAFAFA" }}>
            <FlexCol>
                <div className="flex flex-col sm:flex-row justify-between">
                    <div className='d-flex space-x-2'>
                        <Heading title='Milking Statistics' />
                        <Dropdownbtn title='Today' style={{ backgroundColor: "#FAFAFA", color: "#5A5555", border: '1px #CCCBCB solid' }} />
                    </div>
                    <div className="d-flex space-x-5">
                        <SearchBox placeholder='Search Cattle' className='bg-[#F8F9FB]' />
                        <Filterbtn />
                        <CommonButton title='Add For Multiple Cattle' onClick={handleButtonClick} />
                    </div>
                </div>

                <Nav pills className="nav-success ">
                    {["All", "Inseminated", "Heated Cattle", "Pregnant", "Milking"].map((tabs, index) => (
                        <NavItem key={index}>
                            <NavLink
                                style={{
                                    cursor: "pointer",
                                    color: "#0A0B0A",
                                    backgroundColor: tab === String(index + 1) ? '#E3E3E3' : 'transparent',
                                    fontFamily: "Plus Jakarta Sans",
                                    fontSize: "12px",
                                    fontWeight: 600,
                                    lineHeight: "120%",
                                    textDecoration: 'none' // Optional underline for active tab
                                }}
                                className={tab === String(index + 1) ? "active" : ""}
                                onClick={() => pillsToggle(String(index + 1))}
                            >
                                {tabs}
                            </NavLink>
                        </NavItem>
                    ))}
                </Nav>
                {tab === "1" && <ReactTable headings={headings} data={data} headingcolor="#D9D9D9" handleAction={handleclick} />}
            </FlexCol>
            <MultipleMilkEntries isOpen={showMilkingStats} toggle={handleButtonClick} />
            <MultipleMilkEntries isOpen={milk} toggle={handleclick} />
        </div>
    )
}

export default MilkingStatistics