import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';

//import images
import avatar1 from "../../assets/Images/Avatar.png";
import { createSelector } from 'reselect';
import { useNavigate } from 'react-router';

const ProfileDropdown = () => {

    const [userName, setUserName] =  useState(
        (localStorage.getItem("name")) );
        const [email,setEmail]= useState(
            (localStorage.getItem("email")) );

    //Dropdown Toggle
    const [isProfileDropdown, setIsProfileDropdown] = useState(false);
    const toggleProfileDropdown = () => {
        setIsProfileDropdown(!isProfileDropdown);
    };
    const navigate = useNavigate();

    // Logout function
    const handleLogout = () => {
        localStorage.removeItem("usertype"); // Clear userType from localStorage
        navigate("/"); // Redirect to login page
        window.location.reload();
    };

    const handleaccountsettings = () => {
        navigate('/accountSettings')
    }


    return (
        <React.Fragment>
            <Dropdown isOpen={isProfileDropdown} toggle={toggleProfileDropdown} className="ms-sm-3 header-item topbar-user">
                <DropdownToggle tag="button" type="button" className="btn shadow-none">
                    <span className="d-flex align-items-center">
                        <img className="rounded-circle header-profile-user" src={avatar1} alt="Header Avatar" />
                        <span className="text-start ms-xl-2 hidden lg:block">
                            <span className="text-[#09090B] text-[14px] font-[500] leading-[20px] break-words">{userName}</span>
                            <span className="d-none d-xl-block text-[12px] font-[400] leading-[20px] break-words text-[#71717A]">{email}</span>
                        </span>
                        <i className="mdi mdi-chevron-down ms-1"></i>
                    </span>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end">
                    <h6 className="dropdown-header font-plus-jakarta-sans">Welcome {userName}!</h6>
                    <DropdownItem href="/profile"><i className="mdi mdi-account-circle  fs-16 align-middle me-1"></i> <span className="align-middle text-[#656565] font-plus-jakarta-sans">Profile</span></DropdownItem>
                    <DropdownItem><i className=" mdi  mdi-account-edit-outline  fs-16 align-middle me-1"></i> <span className="align-middle text-[#656565] font-plus-jakarta-sans" onClick={handleaccountsettings}>Account Settings</span></DropdownItem>
                    <DropdownItem href="#"><i className="mdi mdi-bell-outline fs-16 align-middle me-1"></i> <span className="align-middle text-[#656565] font-plus-jakarta-sans">Notification Settings</span></DropdownItem>

                    <DropdownItem onClick={handleLogout}><i className="mdi mdi-logout  fs-16 align-middle me-1"></i> <span className="align-middle text-[#656565]">Logout</span></DropdownItem>
                </DropdownMenu>
            </Dropdown>
        </React.Fragment>
    );
};



export default ProfileDropdown;