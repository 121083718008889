import React, { useState, useEffect } from 'react';
import { Col, FormGroup, Offcanvas, OffcanvasBody, OffcanvasHeader } from 'reactstrap';
import FlexCol from '../Common/FlexCol';
import CommonButton from '../Common/Button';
import FormInput from '../Common/FormInput';
import { Flex } from 'antd';
import TextArea from '../Common/TextArea';
import DateSelect from '../Common/DateSelector';
import { useFormik } from 'formik';
import * as Yup from 'yup'; 
import API, { action } from '../../Api';
import DropdownButton from '../Common/DropdownButton';

function DiagnoseCattle({ isOpen, toggle }) {
    const [medicines, setMedicines] = useState([{ name: '', dosage: '', slots: [], startDate: '', endDate: '' }]); // Change slot to an array
    const [cattleid,setCattleid]=useState([])
    const tabs = ['Foot & Mouth', 'Other'];
    const medicineNeededOptions = ['Yes', 'No'];
    const medicineSlotOptions = ['Morning', 'Noon', 'Evening', 'All'];
    
    const handleAddMedicine = () => {
        const newMedicine = { name: '', dosage: '', slots: [], startDate: '', endDate: '' }; // Change slot to an array
        setMedicines([...medicines, newMedicine]);
        formik.setFieldValue('medicines', [...medicines, newMedicine]);
    };

    const handleMedicineChange = (index, field, value) => {
        const updatedMedicines = [...medicines];
        updatedMedicines[index][field] = value;
        setMedicines(updatedMedicines);
        formik.setFieldValue('medicines', updatedMedicines);
    };

    const handleSlotChange = (index, slot) => {
        const updatedMedicines = [...medicines];
        const currentSlots = updatedMedicines[index].slots;

        if (currentSlots.includes(slot)) {
            // Remove slot if it's already selected
            updatedMedicines[index].slots = currentSlots.filter(s => s !== slot);
        } else {
            // Add slot if it's not selected
            updatedMedicines[index].slots.push(slot);
        }
        
        setMedicines(updatedMedicines);
        formik.setFieldValue('medicines', updatedMedicines);
    };

    const formik = useFormik({
        initialValues: {
            issueType: 'Foot & Mouth',
            issueDate: '',
            cattleId: '',
            severity: '',
            medicineNeeded: 'No',
            modeOfAdministration: '',
            medicines: medicines,
        },
        validationSchema: Yup.object({
            issueType: Yup.string().required('Required'),
            issueDate: Yup.string().required('Required'),
            cattleId: Yup.string().required('Required'),
            severity: Yup.string().required('Required'),
            medicineNeeded: Yup.string().required('Required'),
            modeOfAdministration: Yup.string().max(200, 'Description is too long'),
        }),
        onSubmit: async (values) => {
            console.log(values, "values")
            try {
                // Map the medicines to the API format
                const formattedMedicines = values.medicines.map((medicine) => ({
                    start_date: medicine.startDate,
                    end_date: medicine.endDate,
                    mode_of_administration: values.modeOfAdministration, // Added from form values
                    medicine_name: medicine.name,
                    dosage: medicine.dosage,
                    slot:  medicine.slots.join(', '),
                }));
        
                await action(API.ADD_DIAGNOSIS, {
                    issue_type: values.issueType,
                    issue_date: values.issueDate,
                    cattle_id: values.cattleId,
                    severity: values.severity,  
                    need_medicine: values.medicineNeeded,
                    created_by: 1,  // Replace with actual user ID if available
                    medicines: formattedMedicines,
                });
        
                formik.resetForm();
                toggle(); // Close the offcanvas
            } catch (error) {
                console.error('Error creating diagnosis:', error);
                // Handle the error as needed
            }
        
        },
    });
    const customToggle = () => {
        // setId(null);
        formik.resetForm(); // Set id to null
        toggle(); // Call the original toggle function
    };
    const severityOptions = [
        { value: 'critical', label: 'Critical' },
        { value: 'normal', label: 'Normal' },
        { value: 'major', label: 'Major' },
        
    ];



    const getCattleId = async () => {
        try {
            const result = await action(API.GET_CATTLE_ID);
            const cattleID = result.response.data.map(id => ({
                label: id.cattle_id, // Display name
                value: id.cattle_id // Unique identifier
            }));
            setCattleid(cattleID); // Set data with both label and value

            console.log(cattleID); // Check if data is in correct format

        } catch (error) {
            console.error("Error fetching role data:", error);
        }
    };


    useEffect(() => {
        getCattleId()
        console.log(cattleid, 'cattleid');

    }, [])



    return (
        <Offcanvas isOpen={isOpen} toggle={customToggle} direction="end" className="!w-[320px] 2xl:!w-[480px] max-h-full overflow-y-auto">
            <FlexCol className="p-4">
                <OffcanvasHeader>
                    <div>
                        <div className="text-[16px] font-semibold 2xl:text-2xl text-[#09090B] leading-[21.6px] break-words font-plus-jakarta-sans">Diagnose Cattle</div>
                        <div className="text-[10px] 2xl:text-sm text-[#726C6C] font-plus-jakarta-sans">Enter symptoms and treatment.</div>
                    </div>
                </OffcanvasHeader>
                <OffcanvasBody>
                    <form onSubmit={formik.handleSubmit}>
                        <FormGroup>
                            <FlexCol gap={20}>
                                <Flex className="grid grid-cols-1 gap-[30px] items-center">
                                    <div style={{ flexDirection: 'column', gap: 14, display: 'flex' }}>
                                        <div className="text-[#16A34A] text-[16px] md:text-[12px] 2xl:text-[16px] font-medium leading-[19.2px] break-words font-plus-jakarta-sans">
                                            Issue Type
                                        </div>
                                        <div className="flex gap-2">
                                            {tabs.map((tab) => (
                                                <div
                                                    key={tab}
                                                    onClick={() => formik.setFieldValue('issueType', tab)}
                                                    className={`p-2 rounded-lg cursor-pointer ${formik.values.issueType === tab ? 'bg-[#E3E3E3]' : 'bg-transparent'} flex justify-center items-center`}
                                                >
                                                    <div className="text-[#0A0B0A] text-[12px] md:text-[10px] font-medium leading-[16.8px] break-words">
                                                        {tab}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>

                                    <DateSelect
                                        title="Issue Date"
                                        placeholder="Issue Date"
                                        value={formik.values.issueDate}
                                        change={(e) => formik.setFieldValue('issueDate', e)}
                                        error={formik.touched.issueDate && formik.errors.issueDate}
                                    />

                                    <DropdownButton
                                        title="Cattle ID"
                                        placeholder="1865"
                                        value={formik.values.cattleId}
                                        options={cattleid}
                                        change={(e) => formik.setFieldValue('cattleId', e)}
                                        error={formik.touched.cattleId && formik.errors.cattleId}
                                    />

                                    <DropdownButton
                                        title="Severity"
                                        placeholder="Critical"
                                        value={formik.values.severity}
                                        options={severityOptions}
                                        change={(e) => formik.setFieldValue('severity', e)}
                                        error={formik.touched.severity && formik.errors.severity}
                                    />

                                    <div style={{ flexDirection: 'column', gap: 14, display: 'flex' }}>
                                        <div className="text-[#16A34A] text-[16px] md:text-[12px] 2xl:text-[16px] font-medium leading-[19.2px] break-words font-plus-jakarta-sans">
                                            Need Medicine
                                        </div>
                                        <div className="flex gap-2">
                                            {medicineNeededOptions.map((option) => (
                                                <div
                                                    key={option}
                                                    onClick={() => formik.setFieldValue('medicineNeeded', option)}
                                                    className={`p-2 rounded-lg cursor-pointer ${formik.values.medicineNeeded === option ? 'bg-[#E3E3E3]' : 'bg-transparent'} flex justify-center items-center`}
                                                >
                                                    <div className="text-[#0A0B0A] text-[12px] md:text-[10px] font-medium leading-[16.8px] break-words">
                                                        {option}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>

                                    {medicines.map((medicine, index) => (
                                        <div key={index} style={{ flexDirection: 'column', gap: 20, display: 'flex' }}>
                                            <FormInput
                                                title="Medicine Name"
                                                placeholder="CC REGULAR MEDICINE"
                                                value={medicine.name}
                                                change={(e) => handleMedicineChange(index, 'name', e)}
                                                error={formik.errors.medicines && formik.errors.medicines[index]?.name}
                                            />
                                            <FormInput
                                                title="Dosage"
                                                placeholder="2.5 mg"
                                                value={medicine.dosage}
                                                change={(e) => handleMedicineChange(index, 'dosage', e)}
                                                error={formik.errors.medicines && formik.errors.medicines[index]?.dosage}
                                            />
                                           <div className="text-[#16A34A] text-[16px] md:text-[12px] 2xl:text-[16px] font-medium leading-[19.2px] break-words font-plus-jakarta-sans">
                                                Medicine Slot
                                            </div>
                                            <div className="flex gap-2">
                                                {medicineSlotOptions.map((slot) => (
                                                    <div
                                                        key={slot}
                                                        onClick={() => handleSlotChange(index, slot)}
                                                        className={`p-2 rounded-lg cursor-pointer ${medicine.slots.includes(slot) ? 'bg-[#E3E3E3]' : 'bg-transparent'} flex justify-center items-center`}
                                                    >
                                                        <div className="text-[#0A0B0A] text-[12px] md:text-[10px] font-medium leading-[16.8px] break-words">
                                                            {slot}
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                            <DateSelect
                                                title="Start Date"
                                                placeholder="Start Date"
                                                value={medicine.startDate}
                                                change={(e) => handleMedicineChange(index, 'startDate', e)}
                                                error={formik.errors.medicines && formik.errors.medicines[index]?.startDate}
                                            />
                                            <DateSelect
                                                title="End Date"
                                                placeholder="End Date"
                                                value={medicine.endDate}
                                                change={(e) => handleMedicineChange(index, 'endDate', e)}
                                                error={formik.errors.medicines && formik.errors.medicines[index]?.endDate}
                                            />
                                        </div>
                                    ))}
                                    <CommonButton title="Add Medicine" onClick={handleAddMedicine} />
                                    <TextArea
                                        title="Mode of Administration"
                                        placeholder="Enter mode of administration here..."
                                        value={formik.values.modeOfAdministration}
                                        change={(e) => formik.setFieldValue('modeOfAdministration', e)}
                                        error={formik.touched.modeOfAdministration && formik.errors.modeOfAdministration}
                                    />
                                </Flex>
                            </FlexCol>
                        </FormGroup>
                        <Col className="flex justify-end mt-5 gap-2">
                        <CommonButton title="Submit" type="submit" />
                        <CommonButton title="Cancel" type="button" onClick={toggle} />
                        </Col>
                    </form>
                </OffcanvasBody>
            </FlexCol>
        </Offcanvas>
    );
}

export default DiagnoseCattle;
