import React, { useEffect, useState } from 'react'
import { FormGroup, Offcanvas, OffcanvasBody, OffcanvasHeader } from 'reactstrap'
import FlexCol from '../Common/FlexCol'
import { Flex } from 'antd'
import DateSelect from '../Common/DateSelector'
import FormInput from '../Common/FormInput'
import TextArea from '../Common/TextArea'
import CommonButton from '../Common/Button'
import { useFormik } from 'formik'
import * as Yup from 'yup';
import API, { action } from '../../Api'
import DropdownButton from '../Common/DropdownButton'
function RecordInsemination({ isOpen, toggle }) {
    const [activeTab, setActiveTab] = useState('Natural');
    const [cattleid,setCattleid]=useState([])
    const tabs = ['Natural', 'Artificial', 'Double AI'];
    const formik = useFormik({
        initialValues: {
            inseminationType: 'Natural',
            inseminationDate: '',
            expectedPregnancy: '',
            cattle: '',
            description: '',
        },
        validationSchema: Yup.object({
            inseminationDate: Yup.date().required('Required'),
            expectedPregnancy: Yup.date().required('Required'),
            cattle: Yup.string().required('Required'),
            description: Yup.string().optional(),
        }),
        onSubmit: async (values) => {
            try {
                // if (id) { // If id is present, update the role
                //     await action(API.UPDATE_USER, {
                //         id: selectedUserId[0].userId, // Add the role ID
                //         full_name: values.userName,
                //         privileges: values.permissions,
                //         role_id: values.userRole,
                //         // password: values.password,
                //         email: values.email
                //     });
                // } else {
                    await action(API. ADD_INSEMINATION, {
                        cattle_id: values.cattle,
                        insemination_type: values.inseminationType,
                        insemination_date: values.inseminationDate,
                        // contact: values.contactNumber,
                        expected_pregnancy: values.expectedPregnancy,
                        description :values.description,
                        created_by : 5
                    });
                // }
                // Optionally reset the form or close the offcanvas
                formik.resetForm();
                toggle(); // Close the offcanvas
            } catch (error) {
                console.error(error);
            }
        },
    });

    const getCattleId = async () => {
        try {
            const result = await action(API.GET_CATTLE_ID);
            const cattleID = result.response.data.map(id => ({
                label: id.cattle_id, // Display name
                value: id.cattle_id // Unique identifier
            }));
            setCattleid(cattleID); // Set data with both label and value

            console.log(cattleID); // Check if data is in correct format

        } catch (error) {
            console.error("Error fetching role data:", error);
        }
    };


    useEffect(() => {
        getCattleId()
        console.log(cattleid, 'cattleid');

    }, [])
  return (
   
        <Offcanvas isOpen={isOpen} toggle={toggle} direction="end"  className="!w-[320px] 2xl:!w-[480px]  max-h-full overflow-y-auto"    >
            <FlexCol className={"p-4"} >

                <OffcanvasHeader >
                    <div >
                        <div className="text-[16px] font-semibold 2xl:text-2xl text-[#09090B]  leading-[21.6px] break-words font-plus-jakarta-sans">Record Insemination</div>
                        <div className="text-[10px] 2xl:text-sm   text-[#726C6C] font-plus-jakarta-sans ">Add Insemination Details</div>
                    </div>
                </OffcanvasHeader>
                <OffcanvasBody>
                    <form onSubmit={formik.handleSubmit}>
                        <FormGroup>
                            <FlexCol className={""} gap={20}>
                                <Flex className="grid grid-cols-1 gap-[30px] items-center">
                                    <div style={{ flexDirection: 'column', justifyContent: 'flex-start', gap: 14, display: 'flex' }}>
                                        <div className="text-[#16A34A] text-[16px] md:text-[12px] 2xl:text-[16px] font-medium leading-[19.2px] break-words font-plus-jakarta-sans">
                                            Insemination Type
                                        </div>
                                        <div className="flex gap-2">
                                            {tabs.map((tab) => (
                                                <div
                                                    key={tab}
                                                    onClick={() => {
                                                        setActiveTab(tab);
                                                        formik.setFieldValue('inseminationType', tab);
                                                    }}
                                                    className={`p-2 rounded-lg cursor-pointer ${activeTab === tab ? 'bg-[#E3E3E3]' : 'bg-transparent'} flex justify-center items-center`}
                                                >
                                                    <div className={`text-[#0A0B0A] text-[12px] md:text-[10px] font-medium leading-[16.8px] break-words`}>
                                                        {tab}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>

                                    <DateSelect
                                        title='Insemination Date'
                                        placeholder='04 Oct 2024'
                                        value={formik.values.inseminationDate}
                                        change={(date) => formik.setFieldValue('inseminationDate', date)}
                                        error={formik.touched.inseminationDate && formik.errors.inseminationDate}
                                    />
                                    <DateSelect
                                        title='Expected Pregnancy'
                                        placeholder='04 Oct 2024'
                                        value={formik.values.expectedPregnancy}
                                        change={(date) => formik.setFieldValue('expectedPregnancy', date)}
                                        error={formik.touched.expectedPregnancy && formik.errors.expectedPregnancy}
                                    />
                                    <DropdownButton
                                        title='Cattle'
                                        placeholder='1865'
                                        options={cattleid}
                                        value={formik.values.cattle}
                                        change={formik.handleChange('cattle')}
                                        onBlur={formik.handleBlur('cattle')}
                                        error={formik.touched.cattle && formik.errors.cattle}
                                    />
                                    <TextArea
                                        title='Description'
                                        placeholder='Add Description'
                                        value={formik.values.description}
                                        change={formik.handleChange('description')}
                                        onBlur={formik.handleBlur('description')}
                                        error={formik.touched.description && formik.errors.description}
                                    />
                                </Flex>
                            </FlexCol>
                        </FormGroup>
                        <div className="flex justify-between mt-8">
                            <CommonButton
                                style={{ fontSize: "12px", backgroundColor: "none", color: "#726C6C", border: "1px #CCCBCB solid", marginRight: "8px" }}
                                onClick={toggle}
                                outline
                                title='Discard'
                            />
                            <CommonButton
                                type='submit'
                                title='Save Insemination'
                                style={{ fontSize: "12px" }}
                            />
                        </div>
                    </form>
                </OffcanvasBody>

            </FlexCol>
        </Offcanvas>
  )
}

export default RecordInsemination