import React from 'react'
import { Offcanvas, OffcanvasBody, OffcanvasHeader } from 'reactstrap';
import FlexCol from '../Common/FlexCol';

function ViewUserDetails({isOpen,toggle,userDetails}) {

    console.log(userDetails,"ooo");
    const hasUserDetails = Array.isArray(userDetails) && userDetails.length > 0;
    const userDetail = hasUserDetails ? userDetails[0] : {};
  return (
    <Offcanvas isOpen={isOpen} toggle={toggle} direction="end"  className="!w-[320px] 2xl:!w-[480px]  max-h-full overflow-y-auto" >
    <FlexCol className={"p-4"}>
        <OffcanvasHeader>
            <div>
                <div className="text-[16px] font-semibold 2xl:text-2xl text-[#09090B] leading-[21.6px] break-words font-plus-jakarta-sans">User Details</div>
                <div className="text-[10px] 2xl:text-sm text-[#726C6C] font-plus-jakarta-sans">User Details</div>
            </div>
        </OffcanvasHeader>
        <OffcanvasBody>
        <div className="text-[#726C6C] font-plus-jakarta-sans text-xs font-semibold  flex flex-row items-center ">
                <div>
                    <p><strong>Name</strong></p>
                    <p><strong>Role</strong></p>
                    <p><strong>Email</strong></p>
                    <p><strong>Mobile No </strong></p>
                    <p><strong>Status</strong></p>
                </div>
                <div className="ml-10 text-[#0A0B0A] capitalize">
                <p>{userDetail.UserID || '--'}</p>
                            <p>{userDetail.Role || '--'}</p>
                            <p className="normal-case">{userDetail.email || '--'}</p>
                            <p>{userDetail.mobile || '--'}</p>
                            <p>{userDetail.status || '--'}</p>
                </div>
                </div>
        </OffcanvasBody>
    </FlexCol>
</Offcanvas>
);
}
  

export default ViewUserDetails