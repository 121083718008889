import React, { useState } from 'react'
import CommonButton from '../Common/Button'
import FormInput from '../Common/FormInput'
import TextArea from '../Common/TextArea'
import DateSelect from '../Common/DateSelector'
import { FormGroup, Offcanvas, OffcanvasBody, OffcanvasHeader } from 'reactstrap'
import FlexCol from '../Common/FlexCol'
import { Flex } from 'antd'
function RecordVaccine({ isOpen, toggle }) {
    const [activeTab, setActiveTab] = useState('Type A && Yes');
    const tabs = ['Type A', ' Type B', 'Type C'];
    const tabs2 = ['Yes', 'No'];
  return (
    <Offcanvas isOpen={isOpen} toggle={toggle} direction="end"  className="!w-[320px] 2xl:!w-[480px]  max-h-full overflow-y-auto"    >
    <FlexCol className={"p-4"} >

        <OffcanvasHeader >
            <div >
                <div className="text-[16px] font-semibold 2xl:text-2xl text-[#09090B]  leading-[21.6px] break-words font-plus-jakarta-sans">Record Vaccine</div>
                <div className="text-[10px] 2xl:text-sm  text-[#726C6C] font-plus-jakarta-sans ">Provide vaccination data and follow-up.</div>
            </div>
        </OffcanvasHeader>
        <OffcanvasBody >
            <FormGroup>
                <FlexCol className={""} gap={20}>
                    <Flex className="grid grid-cols-1 gap-[30px] items-center">
                        <div style={{ flexDirection: 'column', justifyContent: 'flex-start', gap: 14, display: 'flex' }}>
                            <div className="text-[#16A34A] text-[16px] md:text-[12px] 2xl:text-[16px] font-medium leading-[19.2px] break-words font-plus-jakarta-sans">
                            Vaccine Type
                            </div>
                            <div className="flex gap-2">

                                {tabs.map((tab) => (
                                    <div
                                        key={tab}
                                        onClick={() => setActiveTab(tab)}
                                        className={`p-2 rounded-lg cursor-pointer ${activeTab === tab ? 'bg-[#E3E3E3]' : 'bg-transparent'} flex justify-center items-center`}
                                    >
                                        <div className={`text-[#0A0B0A] text-[12px] md:text-[10px] font-medium leading-[16.8px] break-words`}>
                                            {tab}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        {/* <FormGroup>
                <FlexCol className={""} gap={20}>
                <Flex className="grid grid-cols-1 gap-[30px] items-center"> */}
                  <FormInput title='Vaccine Name ' placeholder='Covid Vaccine' />
                  <FormInput title='Dosage' placeholder='2.5 mg' />
                        <DateSelect title='Vaccination Date' placeholder='04 Oct 2024'  />
                        <FormInput title='Cattle ' placeholder='1865' />
                        <FormInput title='Health Status' placeholder='Critical' />
                        <DateSelect title='Next Vaccination Date' placeholder='04 Oct 2024'  />
                        <div style={{ flexDirection: 'column', justifyContent: 'flex-start', gap: 14, display: 'flex' }}>
                            <div className="text-[#16A34A] text-[16px] md:text-[12px] 2xl:text-[16px] font-medium leading-[19.2px] break-words font-plus-jakarta-sans">
                            Need Medicine
                            </div>
                            <div className="flex gap-2">

                                {tabs2.map((tab) => (
                                    <div
                                        key={tab}
                                        onClick={() => setActiveTab(tab)}
                                        className={`p-2 rounded-lg cursor-pointer ${activeTab === tab ? 'bg-[#E3E3E3]' : 'bg-transparent'} flex justify-center items-center`}
                                    >
                                        <div className={`text-[#0A0B0A] text-[12px] md:text-[10px] font-medium leading-[16.8px] break-words font-plus-jakarta-sans`}>
                                            {tab}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <FormInput title='Medicine Name' placeholder='CC REGULAR MEDICINE'  />
                        <FormInput title='Dosage ' placeholder='2.5 mg'  />

                        <TextArea
                            title="Description"
                          
                            placeholder="Add Description"

                        />
                       
                       
                    </Flex>
                </FlexCol>
            </FormGroup>
            {/* </div> */}
            <div className=" flex justify-between mt-8 ">
                <CommonButton
                    style={{ backgroundColor: "none", color: "#726C6C", border: "1px #CCCBCB solid ", marginRight: "8px",fontSize: "12px" }}
                    onClick={toggle}
                    outline
                    title='Discard'
                />



                <CommonButton title='Save' style={{fontSize: "12px"}} />


            </div>
        </OffcanvasBody>

    </FlexCol>
</Offcanvas>
  )
}

export default RecordVaccine