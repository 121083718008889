import React, { useState } from 'react'
import { Col, Row } from 'reactstrap'
import Graph from '../../Common/Graph'
import MyTask from '../../Common/MyTask'
import FlexCol from '../../Common/FlexCol'
import Heading from '../../Common/Heading'
import Cards from '../../Common/Cards'
import PieChart from '../../Common/PieChart'
import Button from '../../Common/Button'
import CommonButton from '../../Common/Button'
import Dropdownbtn from '../../Common/Dropdownbtn'
import CommonCalendar from '../../Common/Calendar'
import DiagnoseCattle from '../../Drawer/DiagnoseCattle'
import { Navigate, useNavigate } from 'react-router'
import Accordion from '../../Common/Accordion'

function DoctoreDashboard() {
  const [report,setReport]=useState(false)
  const DiagnoseReport = () => setReport(!report)

  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/vaccination-report'); // Use the navigate function
  };

  const handlemedicationClick =()=>{
    navigate('/medicationReport')
  }


  const carddata = [
    {
        id: 1,
        cardColor: "primary",
        label: "Issue Cattle",
        badge: "ri-arrow-right-up-line",
        badgeClass: "success",
        percentage: "+16.24",
        counter: "64",
        link: "View net earnings",
        bgcolor: "primary",
        icon: "bx bx-dollar-circle",
        decimals: 2,
        prefix: "$",
        suffix: "k"
    },
    {
        id: 2,
        cardColor: "secondary",
        label: "Ongoing Medication",
        badge: "ri-arrow-right-down-line",
        badgeClass: "danger",
        percentage: "-3.57",
        counter: "32",
        link: "View all orders",
        bgcolor: "primary",
        icon: "bx bx-shopping-bag",
        decimals: 0,
        prefix: "",
        separator: ",",
        suffix: ""
    },
    {
        id: 3,
        cardColor: "success",
        label: "Not on Medication",
        badge: "ri-arrow-right-up-line",
        badgeClass: "success",
        percentage: "+29.08",
        counter: "48",
        link: "See details",
        bgcolor: "primary",
        icon: "bx bx-user-circle",
        decimals: 2,
        prefix: "",
        suffix: "M"
    },
    {
        id: 4,
        cardColor: "info",
        label: "Critical",
        badgeClass: "muted",
        percentage: "+0.00",
        counter: "5",
        link: "Withdraw money",
        bgcolor: "primary",
        icon: "bx bx-wallet",
        decimals: 2,
        prefix: "$",
        suffix: "k"
    },
    {
        id: 1,
        cardColor: "primary",
        label: "Expected Delivery",
        badge: "ri-arrow-right-up-line",
        badgeClass: "success",
        percentage: "+16.24",
        counter: "16",
        link: "View net earnings",
        bgcolor: "primary",
        icon: "bx bx-dollar-circle",
        decimals: 2,
        prefix: "$",
        suffix: "k"
    },
    {
        id: 2,
        cardColor: "secondary",
        label: "Expected Heat",
        badge: "ri-arrow-right-down-line",
        badgeClass: "danger",
        percentage: "-3.57",
        counter: "13",
        link: "View all orders",
        bgcolor: "primary",
        icon: "bx bx-shopping-bag",
        decimals: 0,
        prefix: "",
        separator: ",",
        suffix: ""
    },
    {
        id: 3,
        cardColor: "success",
        label: "Pregnant Cattle",
        badge: "ri-arrow-right-up-line",
        badgeClass: "success",
        percentage: "+29.08",
        counter: "12",
        link: "See details",
        bgcolor: "primary",
        icon: "bx bx-user-circle",
        decimals: 2,
        prefix: "",
        suffix: "M"
    },
    {
        id: 4,
        cardColor: "info",
        label: "Calf & Heifer",
        badgeClass: "muted",
        percentage: "+0.00",
        counter: "32",
        link: "Withdraw money",
        bgcolor: "primary",
        icon: "bx bx-wallet",
        decimals: 2,
        prefix: "$",
        suffix: "k"
    },
];

const medicationChecklist = [
  {
      id: 1,
      forId: "task_one",
      text: "AB Medicine for cattle 5846",
      date: "15 Sep, 2021",
  },
  {
      id: 2,
      forId: "task_two",
      text: "Deworming of Cattle 5468",
      date: "20 Sep, 2021",
  },
  {
      id: 3,
      forId: "task_three",
      text: "CC Medicine of Cattle 5468",
      date: "24 Sep, 2021",
  },
  {
      id: 4,
      forId: "task_four",
      text: "AB Medicine for cattle 5846",
      date: "27 Sep, 2021",
  },
  {
      id: 5,
      forId: "task_five",
      text: "CC Medicine of Cattle 5468",
      date: "27 Sep, 2021",
  },
  {
      id: 6,
      forId: "task_six",
      text: "AB Medicine for cattle 5846",
      date: "27 Sep, 2021",
  },
];

const vaccineChecklist = [
  {
      id: 1,
      forId: "task_one",
      text: "Vaccine  Cattle 5846",
      date: "15 Sep, 2021",
  },
  {
      id: 2,
      forId: "task_two",
      text: "Vaccine  Cattle 5846",
      date: "20 Sep, 2021",
  },
  {
      id: 3,
      forId: "task_three",
      text: "Vaccine  Cattle 5846",
      date: "24 Sep, 2021",
  },
  {
      id: 4,
      forId: "task_four",
      text: "Vaccine  Cattle 5846",
      date: "27 Sep, 2021",
  },
  {
      id: 5,
      forId: "task_five",
      text: "Vaccine  Cattle 5846",
      date: "27 Sep, 2021",
  },
  {
      id: 6,
      forId: "task_six",
      text: "Vaccine  Cattle 5846",
      date: "27 Sep, 2021",
  },
];
  return (
    <div style={{backgroundColor:"#FAFAFA"}}>
    <FlexCol>
    <div className="flex flex-col sm:flex-row justify-between">
    <div className='d-flex space-x-2'>
  <Heading title='Cattle  Health Dashboard' />
  <Dropdownbtn title='This Month' style={{backgroundColor:"#FAFAFA",color:"#5A5555",border: '1px #CCCBCB solid'}}/>
</div>
  <CommonButton title='Diagnose Cattle' onClick={DiagnoseReport}/>
</div>


   <Cards data={carddata} style={{color:"#E4626F"}}/>
   {/* <div className="grid grid-cols-16 gap-4 ">
   <div className="col-span-12 xl:col-span-8">
   <Graph/>
    </div>
    <div className="col-span-8 xl:col-span-8">
    <MyTask/>
    </div> 
     </div> */}
    <Row className="gx-3 gy-4">
  <Col xl={6}>
    {/* <Graph title='Cattle Health Statistics' /> */}
    <MyTask heading="Medication Checklist" data={medicationChecklist} btntitle='Add Medication' onclick={handlemedicationClick} />
  </Col>
  <Col xl={6}>
    {/* <PieChart /> */}
    <MyTask heading='Vaccine Checklist' data={vaccineChecklist} btntitle='Add New' onclick={handleClick}/>
  </Col>
</Row>
<Row className="gx-3 gy-4">
  <Col xl={6} >
  <PieChart title="Sick Cattle Distribution" />
  </Col>
  <Col xl={6}>
   
    <CommonCalendar/>
  </Col>
</Row>
<Row style={{ marginTop: "5px" }}>
<Graph title='Cattle Health Statistics' />

  </Row>
 
  </FlexCol>
  <DiagnoseCattle isOpen={report} toggle={DiagnoseReport}/>
</div>
  )
}

export default DoctoreDashboard