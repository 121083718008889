import React, { useState } from 'react'
import ReactTable from '../Common/Table';
import { Nav, NavItem, NavLink } from 'reactstrap';
import CommonButton from '../Common/Button';
import Filterbtn from '../Common/Filterbtn';
import SearchBox from '../Common/SearchBox';
import Dropdownbtn from '../Common/Dropdownbtn';
import Heading from '../Common/Heading';
import FlexCol from '../Common/FlexCol';
import cowicon from '../../assets/Images/Cow icon.png'
import RecordMedication from '../Drawer/RecordMedication';

function MedicationReport() {
    const [report,setReport]=useState(false)
    const [medicine,setMedicine]=useState(false)
    const DiagnoseReport = () => setReport(!report)
    const [tab, setTab] = useState("1");
    const pillsToggle = (tabs) => {
      if (tab !== tabs) {
          setTab(tabs);
      }
    };

    const headings = [

        {
            id: 1,
            title: "Sl No",
            value: "SlNo",

        },
        {
            id: 2,
            title: "Cattle ID",
            value: "CattleID",
            icon:cowicon


        },
        {
            id: 3,
            title: "Lactation Number",
            value: "LactationNumber",

        },

    
        {
            id: 4,
            title: "Last Calving date",
            value: "LastCalvingdate",


        },
        {
            id: 5,
            title: "Severity",
            value: "Severity",


        },
        {
            id: 6,
            title: "Heat Date",
            value: "HeatDate",


        },
        {
            id: 7,
            title: "Action",
            value: "action",


        },



    ]

    const data = [
        {
            SlNo: "01",
            CattleID: "Cattle No.1856",
            LactationNumber: "2",
            LastCalvingdate: "06-09-2024",
            Severity: "Normal",
            HeatDate: "11-10-2024",
            action: "Give Medicine",
        },
        {
            SlNo: "02",
            CattleID: "Cattle No.1987",
            LactationNumber: "3",
            LastCalvingdate: "02-08-2024",
            Severity: "Normal",
            HeatDate: "12-10-2024",
            action: "View Report",
        },
        {
            SlNo: "03",
            CattleID: "Cattle No.1745",
            LactationNumber: "1",
            LastCalvingdate: "15-06-2024",
            Severity: "Major",
            HeatDate: "10-11-2024",
            action: "View Report",
        },
        {
            SlNo: "04",
            CattleID: "Cattle No.1623",
            LactationNumber: "4",
            LastCalvingdate: "20-07-2024",
            Severity: "Normal",
            HeatDate: "05-10-2024",
            action: "Give Medicine",
        },
        {
            SlNo: "05",
            CattleID: "Cattle No.1456",
            LactationNumber: "2",
            LastCalvingdate: "25-05-2024",
            Severity: "Critical",
            HeatDate: "30-09-2024",
            action: "View Report",
        },
        {
            SlNo: "06",
            CattleID: "Cattle No.1543",
            LactationNumber: "3",
            LastCalvingdate: "18-08-2024",
            Severity: "Normal",
            HeatDate: "20-10-2024",
            action: "Give Medicine",
        },
        {
            SlNo: "07",
            CattleID: "Cattle No.1678",
            LactationNumber: "1",
            LastCalvingdate: "01-09-2024",
            Severity: "Critical",
            HeatDate: "15-11-2024",
            action: "View Report",
        },
        {
            SlNo: "08",
            CattleID: "Cattle No.1234",
            LactationNumber: "4",
            LastCalvingdate: "12-06-2024",
            Severity: "Normal",
            HeatDate: "25-10-2024",
            action: "Give Medicine",
        },
        {
            SlNo: "09",
            CattleID: "Cattle No.1098",
            LactationNumber: "3",
            LastCalvingdate: "14-08-2024",
            Severity: "Normal",
            HeatDate: "02-11-2024",
            action: "View Report",
        },
        {
            SlNo: "10",
            CattleID: "Cattle No.1999",
            LactationNumber: "2",
            LastCalvingdate: "27-07-2024",
            Severity: "Normal",
            HeatDate: "16-10-2024",
            action: "Give Medicine",
        },
        {
            SlNo: "11",
            CattleID: "Cattle No.1321",
            LactationNumber: "1",
            LastCalvingdate: "03-09-2024",
            Severity: "Normal",
            HeatDate: "28-11-2024",
            action: "View Report",
        },
        {
            SlNo: "12",
            CattleID: "Cattle No.1156",
            LactationNumber: "4",
            LastCalvingdate: "30-05-2024",
            Severity: "Critical",
            HeatDate: "14-10-2024",
            action: "Give Medicine",
        },
        {
            SlNo: "13",
            CattleID: "Cattle No.1425",
            LactationNumber: "3",
            LastCalvingdate: "19-08-2024",
            Severity: "Major",
            HeatDate: "01-11-2024",
            action: "Give Medicine",
        },
        {
            SlNo: "14",
            CattleID: "Cattle No.1867",
            LactationNumber: "2",
            LastCalvingdate: "10-07-2024",
            Severity: "Major",
            HeatDate: "15-10-2024",
            action: "Give Medicine",
        },
        {
            SlNo: "15",
            CattleID: "Cattle No.1789",
            LactationNumber: "1",
            LastCalvingdate: "22-09-2024",
            Severity: "Critical",
            HeatDate: "29-11-2024",
            action: "Give Medicine",
        },
    ];
    const data2 = [
        {
            SlNo: "01",
            CattleID: "Cattle No.1856",
            LactationNumber: "2",
            LastCalvingdate: "06-09-2024",
            Severity: "Normal",
            HeatDate: "11-10-2024",
            action: "Give Medicine",
        },
        {
            SlNo: "02",
            CattleID: "Cattle No.1987",
            LactationNumber: "3",
            LastCalvingdate: "02-08-2024",
            Severity: "Normal",
            HeatDate: "12-10-2024",
            action: "View Report",
        },
       
        {
            SlNo: "07",
            CattleID: "Cattle No.1678",
            LactationNumber: "1",
            LastCalvingdate: "01-09-2024",
            Severity: "Critical",
            HeatDate: "15-11-2024",
            action: "View Report",
        },
        {
            SlNo: "08",
            CattleID: "Cattle No.1234",
            LactationNumber: "4",
            LastCalvingdate: "12-06-2024",
            Severity: "Normal",
            HeatDate: "25-10-2024",
            action: "Give Medicine",
        },
        
        {
            SlNo: "14",
            CattleID: "Cattle No.1867",
            LactationNumber: "2",
            LastCalvingdate: "10-07-2024",
            Severity: "Major",
            HeatDate: "15-10-2024",
            action: "Give Medicine",
        },
        {
            SlNo: "15",
            CattleID: "Cattle No.1789",
            LactationNumber: "1",
            LastCalvingdate: "22-09-2024",
            Severity: "Critical",
            HeatDate: "29-11-2024",
            action: "Give Medicine",
        },
    ];
    const data3 = [
        {
            SlNo: "01",
            CattleID: "Cattle No.1856",
            LactationNumber: "2",
            LastCalvingdate: "06-09-2024",
            Severity: "Normal",
            HeatDate: "11-10-2024",
            action: "Give Medicine",
        },
        {
            SlNo: "02",
            CattleID: "Cattle No.1987",
            LactationNumber: "3",
            LastCalvingdate: "02-08-2024",
            Severity: "Normal",
            HeatDate: "12-10-2024",
            action: "View Report",
        },
        {
            SlNo: "03",
            CattleID: "Cattle No.1745",
            LactationNumber: "1",
            LastCalvingdate: "15-06-2024",
            Severity: "Major",
            HeatDate: "10-11-2024",
            action: "View Report",
        },
        {
            SlNo: "04",
            CattleID: "Cattle No.1623",
            LactationNumber: "4",
            LastCalvingdate: "20-07-2024",
            Severity: "Normal",
            HeatDate: "05-10-2024",
            action: "Give Medicine",
        },
        {
            SlNo: "05",
            CattleID: "Cattle No.1456",
            LactationNumber: "2",
            LastCalvingdate: "25-05-2024",
            Severity: "Critical",
            HeatDate: "30-09-2024",
            action: "View Report",
        },
        
    ];
    const data4 = [
        {
            SlNo: "01",
            CattleID: "Cattle No.1856",
            LactationNumber: "2",
            LastCalvingdate: "06-09-2024",
            Severity: "Normal",
            HeatDate: "11-10-2024",
            action: "Give Medicine",
        },
        {
            SlNo: "02",
            CattleID: "Cattle No.1987",
            LactationNumber: "3",
            LastCalvingdate: "02-08-2024",
            Severity: "Normal",
            HeatDate: "12-10-2024",
            action: "View Report",
        },
        {
            SlNo: "03",
            CattleID: "Cattle No.1745",
            LactationNumber: "1",
            LastCalvingdate: "15-06-2024",
            Severity: "Major",
            HeatDate: "10-11-2024",
            action: "View Report",
        },
        {
            SlNo: "04",
            CattleID: "Cattle No.1623",
            LactationNumber: "4",
            LastCalvingdate: "20-07-2024",
            Severity: "Normal",
            HeatDate: "05-10-2024",
            action: "Give Medicine",
        },
        {
            SlNo: "05",
            CattleID: "Cattle No.1456",
            LactationNumber: "2",
            LastCalvingdate: "25-05-2024",
            Severity: "Critical",
            HeatDate: "30-09-2024",
            action: "View Report",
        },
       
    ];
    const data5 = [
        {
            SlNo: "01",
            CattleID: "Cattle No.1856",
            LactationNumber: "2",
            LastCalvingdate: "06-09-2024",
            Severity: "Normal",
            HeatDate: "11-10-2024",
            action: "Give Medicine",
        },
        {
            SlNo: "02",
            CattleID: "Cattle No.1987",
            LactationNumber: "3",
            LastCalvingdate: "02-08-2024",
            Severity: "Normal",
            HeatDate: "12-10-2024",
            action: "View Report",
        },
        {
            SlNo: "03",
            CattleID: "Cattle No.1745",
            LactationNumber: "1",
            LastCalvingdate: "15-06-2024",
            Severity: "Major",
            HeatDate: "10-11-2024",
            action: "View Report",
        },
        {
            SlNo: "04",
            CattleID: "Cattle No.1623",
            LactationNumber: "4",
            LastCalvingdate: "20-07-2024",
            Severity: "Normal",
            HeatDate: "05-10-2024",
            action: "Give Medicine",
        },
        {
            SlNo: "05",
            CattleID: "Cattle No.1456",
            LactationNumber: "2",
            LastCalvingdate: "25-05-2024",
            Severity: "Critical",
            HeatDate: "30-09-2024",
            action: "View Report",
        },
        {
            SlNo: "06",
            CattleID: "Cattle No.1543",
            LactationNumber: "3",
            LastCalvingdate: "18-08-2024",
            Severity: "Normal",
            HeatDate: "20-10-2024",
            action: "Give Medicine",
        },
        
    ];
    const data6 = [
        {
            SlNo: "01",
            CattleID: "Cattle No.1856",
            LactationNumber: "2",
            LastCalvingdate: "06-09-2024",
            Severity: "Normal",
            HeatDate: "11-10-2024",
            action: "Give Medicine",
        },
        {
            SlNo: "02",
            CattleID: "Cattle No.1987",
            LactationNumber: "3",
            LastCalvingdate: "02-08-2024",
            Severity: "Normal",
            HeatDate: "12-10-2024",
            action: "View Report",
        },
        {
            SlNo: "03",
            CattleID: "Cattle No.1745",
            LactationNumber: "1",
            LastCalvingdate: "15-06-2024",
            Severity: "Major",
            HeatDate: "10-11-2024",
            action: "View Report",
        },
        {
            SlNo: "04",
            CattleID: "Cattle No.1623",
            LactationNumber: "4",
            LastCalvingdate: "20-07-2024",
            Severity: "Normal",
            HeatDate: "05-10-2024",
            action: "Give Medicine",
        },
        
        
    ];
    // const handleActionClick = (item) => {
    //     console.log('Action performed on item:', item);
    //     setReport(!report);
       
        
    //   };
    const handleActionClick = () => setMedicine(!medicine)
      console.log(report,"report");
    return (
        <div style={{ backgroundColor: "#FAFAFA",minHeight: "100vh"  }}>
            <FlexCol>
                <div className="d-flex justify-content-between ">
                    <div className='d-flex space-x-2'>
                    <Heading title='Medication Record' />
                    <Dropdownbtn title='Today' style={{backgroundColor:"#FAFAFA",color:"#5A5555",border: '1px #CCCBCB solid'}} />
                    </div>
                    <div className="d-flex space-x-5">
                    <SearchBox placeholder='Search Cattle' className='bg-[#F8F9FB]' />
                    <Filterbtn />
                        <CommonButton title=" Record For All" onClick={DiagnoseReport}/>
                    </div>
                </div>
                {/* <Cards />
                <Row className="g-3">
                    <Col md={7} >
                        <Graph title='Sick Cattle Statistics' />

                    </Col>
                    <Col md={5} >
                        <PieChart title="Sick Cattle Distribution" />

                    </Col>

                </Row> */}
                 <Nav pills className="nav-success ">
          {["All", "Not Diagnosed", "In medication", "Not in medication","Critical","Recovered"].map((tabs, index) => (
            <NavItem key={index}>
              <NavLink
                style={{
                  cursor: "pointer",
                  color: "#0A0B0A",
                  backgroundColor: tab === String(index + 1) ? '#E3E3E3' : 'transparent',
                  fontFamily: "Plus Jakarta Sans",
                  fontSize: "12px",
                  fontWeight: 600,
                  lineHeight: "120%",
                  textDecoration: 'none' // Optional underline for active tab
                }}
                className={tab === String(index + 1) ? "active" : ""}
                onClick={() => pillsToggle(String(index + 1))}
              >
                {tabs}
              </NavLink>
            </NavItem>
          ))}
        </Nav>
        {tab === "1" && <ReactTable headings={headings} data={data} headingcolor="#D9D9D9" handleAction={handleActionClick} />}
        {tab === "2" && <ReactTable headings={headings} data={data2} headingcolor="#D9D9D9" handleAction={handleActionClick} />}
        {tab === "3" && <ReactTable headings={headings} data={data3} headingcolor="#D9D9D9" handleAction={handleActionClick} />}
        {tab === "4" && <ReactTable headings={headings} data={data4} headingcolor="#D9D9D9" handleAction={handleActionClick} />}
        {tab === "5" && <ReactTable headings={headings} data={data5} headingcolor="#D9D9D9" handleAction={handleActionClick} />}
        {tab === "6" && <ReactTable headings={headings} data={data6} headingcolor="#D9D9D9" handleAction={handleActionClick} />}
            </FlexCol>
            <RecordMedication isOpen={report} toggle={ DiagnoseReport}/>
            <RecordMedication isOpen={medicine} toggle={ handleActionClick}/>
           
        </div>
    )
}

export default MedicationReport