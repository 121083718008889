import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react'
import API, { action } from '../../Api';
import * as Yup from 'yup';
import { FormGroup, Offcanvas, OffcanvasBody, OffcanvasHeader } from 'reactstrap';
import FlexCol from '../Common/FlexCol';
import { Flex } from 'antd';
import FormInput from '../Common/FormInput';
import CommonButton from '../Common/Button';

function ChangePassword({ isOpen, toggle ,user }) {

  
  
    
    const [id,setid] =useState(user)
    
    console.log(id,"userrrr");
    console.log(user,"userrrr");
    // const userId = user.userId || null;


    // console.log(userId,"userrrridd");
    useEffect(() => {
        setid(user);
    }, [user]);
    
    const formik = useFormik({
        initialValues: {
            currentPassword: '',
            password:'',
            confirmPassword: '',
            // userid: Array.isArray(user) && user.length > 0 ? user[0].UserID : null,
            // userid:user?.UserID 
             id:id
           
        },
        validationSchema: Yup.object({
            currentPassword: Yup.string().required('current password is required'),
            password: Yup.string().required('New password is required'),
            confirmPassword: Yup.string()
                .required('Confirm password is required')
                .oneOf([Yup.ref('password'), null], 'Passwords must match'),
            // contactNumber: Yup.string().required('Contact Number is required'),
           
        }),
        onSubmit: async (values) => {
            console.log(values,"values");
            
            try {
                if (values.currentPassword === values.password) { 
                    formik.setFieldError('newPassword', 'New password must not match with old password');
                    return;
                  }
                  if (values.password !== values.confirmPassword) {
                    // Handle error if passwords do not match
                    formik.setFieldError('confirmpassword', 'Passwords must match');
                    return;
                  }
                await action(API.UPDATE_PASSWORD, {
                    user_id: id,
                    password: values.confirmPassword,
                  
                });
                // Optionally reset the form or close the offcanvas
                formik.resetForm();
                toggle(); // Close the offcanvas
            } catch (error) {
                console.error('Error creating user:', error);
                // Handle the error as needed
            }
        },
    });




    return (
        <Offcanvas isOpen={isOpen} toggle={toggle} direction="end" className="!w-[320px] 2xl:!w-[480px] max-h-full overflow-y-auto">
            <FlexCol className="p-4">
                <OffcanvasHeader>
                    <div>
                        <div className="text-[16px] font-semibold 2xl:text-2xl text-[#09090B] leading-[21.6px] break-words font-plus-jakarta-sans">
                            Update Password
                        </div>
                        <div className="text-[10px] 2xl:text-sm text-[#726C6C] font-plus-jakarta-sans">
                        Update Password
                        </div>
                    </div>
                </OffcanvasHeader>
                <OffcanvasBody>
                    <form onSubmit={formik.handleSubmit}>
                        <FormGroup>
                            <FlexCol className="" gap={20}>
                                <Flex className="grid grid-cols-1 gap-[30px] items-center">
                                    <FormInput
                                        title="Current Password"
                                        placeholder="User Name"
                                        value={formik.values.currentPassword}
                                        change={(e) => formik.setFieldValue("currentPassword", e)}
                                        error={formik.touched.userName && formik.errors.currentPassword}
                                    />
                                
                                   
                                    <FormInput
                                        title="New Password"
                                        placeholder="Password"
                                        type="password"
                                        value={formik.values.password}
                                        change={(e) => formik.setFieldValue("password", e)}
                                        error={formik.touched.password && formik.errors.password}
                                    />
                                    <FormInput
                                        title="Confirm Password"
                                        placeholder="Password"
                                        type="password"
                                        value={formik.values.confirmPassword}
                                        change={(e) => formik.setFieldValue("confirmPassword", e)}
                                        error={formik.touched.confirmPassword && formik.errors.confirmPassword}
                                    />
                                </Flex>
                            </FlexCol>
                        </FormGroup>
                        <div className="fixed bottom-0 left-0 right-0 flex justify-end p-4">
                            <CommonButton
                                style={{ backgroundColor: "none", color: "#726C6C", border: "1px #CCCBCB solid", marginRight: "8px", fontSize: "12px" }}
                                onClick={toggle}
                                outline
                                title='Cancel'
                            />
                            <CommonButton title='Change Password' style={{ fontSize: "12px" }} type="submit" />
                        </div>
                    </form>
                </OffcanvasBody>
            </FlexCol>
        </Offcanvas>
        
    );
}

export default ChangePassword
