import React, { useEffect, useState } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

function Dropdownbtn({ title = "", options = [], style = {}, onSelect }) {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen(prevState => !prevState);

  const handleSelect = (option) => {
    if (onSelect) {
      onSelect(option);
    }
  };
  const [fontSize, setFontSize] = useState('14px');

  useEffect(() => {
    function handleResize() {
      const screenWidth = window.innerWidth;

      if (screenWidth <= 360) {
        setFontSize('5px'); // Very small screens (phones)
      } else if (screenWidth <= 480) {
        setFontSize('5px'); // Small phones
      } else if (screenWidth <= 600) {
        setFontSize('5px'); // Medium phones
      } else if (screenWidth <= 768) {
        setFontSize('6px'); // Tablets and small screens
      } else if (screenWidth <= 1024) {
        setFontSize('7px'); // Medium tablets and small laptops
      } else if (screenWidth <= 1280) {
        setFontSize('8px'); // Laptops and small desktops
      } else if (screenWidth <= 1440) {
        setFontSize('8px'); // Larger laptops and desktops
      } else {
        setFontSize('18px'); // Very large screens
      }
    }

    window.addEventListener('resize', handleResize);

    // Initial check
    handleResize();

    // Cleanup event listener on unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const defaultStyle = {
    borderRadius: '10px ',
    fontFamily: 'Plus Jakarta Sans',
    fontSize: fontSize,
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '70%',
    backgroundColor: "#16A34A",
    color: "#ffff",
  };

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle style={{ ...defaultStyle, ...style }} caret>
        {title}
      </DropdownToggle>
      <DropdownMenu>
        {options.map((option, index) => (
          <DropdownItem key={index} onClick={() => handleSelect(option)}>
            {option}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
}

export default Dropdownbtn;
