import React, { useState } from 'react';
import Login from './Components/Login/Login';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Header from './Components/Header/Header';
import DashBoard from './Components/Dashboard/DashBoard';
import SideBar from './Components/Common/SideBar';
import Userdashboard from './Components/Dashboard/User Dashboard/Userdashboard';
import DoctoreDashboard from './Components/Dashboard/DoctoreDashboard/DoctoreDashboard';
import AdminDashboard from './Components/Dashboard/AdminDashbord/AdminDashboard';
import CattleDashboard from './Components/CattleDashboard/CattleDashboard';
import CattleReport from './Components/CattleReport/CattleReport';
import FeedInventory from './Components/Feed Inventory/FeedInventory';
import MilkReport from './Components/Milk Report/MilkReport';
import ActivityLog from './Components/Activity Log/ActivityLog';
import HealthMonitoring from './Components/Health Monitoring/HealthMonitoring';
import UserMangement from './Components/User Management/UserMangement';
import Vaccination from './Components/Vaccination/Vaccination';
import MedicationReport from './Components/MedicationReport/MedicationReport';
import VaccinationReport from './Components/Vaccination/VaccinationReport';
import Breeding from './Components/Breeding/Breeding';
import ReportsMonitoring from './Components/Reports Monitoring/ReportsMonitoring';
import CattleHealthReport from './Components/Reports Monitoring/CattleHealthReport';
import MilkingReport from './Components/Reports Monitoring/MilkingReport';
import CattleBreedingReport from './Components/Reports Monitoring/CattleBreedingReport';
import CattleFeedingReport from './Components/Reports Monitoring/CattleFeedingReport';
import RoleList from './Components/User Management/RoleList';
import AccountSettings from './Components/Settings/AccountSettings';

const styles = {
  layout: {
    display: 'flex',
    minHeight: '100vh',
    fontFamily: ' Plus Jakarta Sans', // Ensure the font is included
  },
  mainContent: {
    flex: 1, // Take remaining space
    marginLeft: '280px', // Match sidebar width
    
    backgroundColor: 'white',
  },
  header: {
    position: 'absolute',
    top: 0,
    left: '0px', // Start after sidebar
    right: 0,
    height: '70px', // Height of the header
    zIndex: 999,
    backgroundColor: 'white', // Optional: to keep the header white
  },
  content: {
    padding: '2rem',
    marginTop: '70px', // Space for the header
    backgroundColor:'#FAFAFA'
  },
};


export default function Router() {
  const [loginData, setLoginData] = useState(
    (localStorage.getItem("usertype"))
   
    
  );


  console.log((localStorage.getItem("usertype")),"ttt");
  
  return (
    <BrowserRouter>
      {loginData?<div style={styles.layout}>
        <SideBar />
        <div  className="flex-1 ml-0 md:ml-56 sm:ml-0 lg:ml-52 xl:ml-56 2xl:ml-72 bg-white">
          <div style={styles.header}>
            <Header />
          </div>
          <div style={styles.content}>
            <Routes>
              {/* <Route path="/" element={<Login />} /> */}
              <Route path="/header" element={<Header />} />
              <Route path="/dashboard" element={<DashBoard />} />
              <Route path="/userdashboard" element={<Userdashboard />} />
              <Route path="/" element={<DoctoreDashboard />} />
              <Route path="/adminDashboard" element={<AdminDashboard />} />
              <Route path="/cattleDashboard" element={<CattleDashboard />} />
              <Route path="/cattleReport" element={<CattleReport />} />
              <Route path="/feedInventory" element={<FeedInventory/>} />
              <Route path="/milkReport" element={<MilkReport/>} />
              <Route path="/activityLog" element={<ActivityLog/>} />
              <Route path="/healthMonitoring" element={<HealthMonitoring/>} />
              <Route path="/userManagement" element={<UserMangement/>} />
              
                
              <Route path="/vaccination" element={<Vaccination/>} />
              <Route path="/medicationReport" element={<MedicationReport/>} />
              <Route path="/vaccination-report" element={<VaccinationReport />} />
              <Route path="/breeding" element={<Breeding />} />
              <Route path="/reportsMonitoring" element={<ReportsMonitoring />} />
              <Route path="/cattleHealthReport" element={<CattleHealthReport />} />
              <Route path="/milkingReport" element={<MilkingReport />} />
              <Route path="/cattleBreedingReport" element={<CattleBreedingReport />} />
              <Route path="/cattleFeedingReport" element={<CattleFeedingReport />} />
              <Route path="/userRole" element={<RoleList />} />
              <Route path="/accountSettings" element={<AccountSettings />} />
            </Routes>
          </div>
        </div>
      </div>:
      <Routes>
              <Route path="/" element={<Login />} />
      </Routes>
              }
    </BrowserRouter>
  );
}
