import { Provider } from "react-redux";
import "./App.css";
import "../src/assets/css/style.css"



import Router from "./Router";
import { store } from "./slices/store";
import { ConfigProvider } from "antd";

function App() {
  return (
    <ConfigProvider
    theme={{
      token: {
        // Seed Token
        colorPrimary: '#16A34A',
       

        // Alias Token
        // colorBgContainer: '#f6ffed',
      },
    }}
  >
    <Provider store={store}>
      <div className="App">
        <Router />
      </div>
    </Provider>
    </ConfigProvider>
  );
}

export default App;
