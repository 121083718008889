import React, { useEffect, useState } from 'react'
import CommonButton from '../Common/Button'
import { FormGroup, Offcanvas, OffcanvasBody, OffcanvasHeader } from 'reactstrap'
import FlexCol from '../Common/FlexCol'
import { Flex } from 'antd'
import DateSelect from '../Common/DateSelector'
import FormInput from '../Common/FormInput'
import TextArea from '../Common/TextArea'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import API, { action } from '../../Api'
import DropdownButton from '../Common/DropdownButton'
function AddPregnancy({ isOpen, toggle, selectedData }) {
    const [selectedid, setSelectedid] = useState('');

   
    const id = localStorage.getItem('userid');
    const validationSchema = Yup.object({
        inseminationType: Yup.string().required('Insemination Type is required'),
        inseminationDate: Yup.date().required('Insemination Date is required'),
        cattleId: Yup.string().required('Cattle ID is required'),
        description1: Yup.string().required('Description is required'),
        confirmedDate: Yup.date().required('Confirmed Date is required'),
        expectedDelivery: Yup.date().required('Expected Delivery Date is required'),
        description2: Yup.string().required('Description is required'),
    })

    // Initialize Formik
    const formik = useFormik({
        initialValues: {
          
            inseminationDate: '',
            cattleId: '',
            description1: '',
            confirmedDate: '',
            expectedDelivery: '',
            description2: '',
            serverity :'',
            // insemination_id :selectedid,
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {  // Make onSubmit async
            // Handle form submission
            console.log('Form submitted with values:', values);
    
            try {
                // Perform the async action here
                await action(API.MARK_PREGNANCY, {
                    cattle_id: values.cattleId,
                    insemination_id: selectedid,
                    confirmation_date :values.confirmedDate,
                    expected_delivery_date : values.expectedDelivery,
                    serverity : values.serverity,
                    pregnancy_notes : values.description2,
                    created_by : id
                });
                
                // Reset the form and close the offcanvas after submission
                formik.resetForm();
                toggle();  // Assuming `toggle` is defined to close the offcanvas
            } catch (error) {
                // console.error(id ? 'Error updating role:' : 'Error creating role:', error);
            }
        }
    });

    useEffect(() => {
        if (selectedData && selectedData.length > 0) {
            formik.setFieldValue("inseminationType", selectedData[0]?.InseminationType);
            formik.setFieldValue("inseminationDate", selectedData[0]?.InseminationDate);
            formik.setFieldValue("cattleId", selectedData[0]?.CattleID);
        
            
            console.log(selectedData[0]?.ID,"LLLK");
            setSelectedid(selectedData[0]?.ID);
        }
    }, [selectedData]);

console.log(selectedData,"hhh");


const option = [{label:"Normal",value:"normal"},{label:"Need care",value:"need_care"},{label:"Critical",value:"critical"}]
    return (
        <Offcanvas isOpen={isOpen} toggle={toggle} direction="end" className="!w-[320px] 2xl:!w-[480px]  max-h-full overflow-y-auto"   >
            <FlexCol className={"p-4"} >

                <OffcanvasHeader>
                    <div>
                        <div className="text-[16px] font-semibold 2xl:text-2xl text-[#09090B] leading-[21.6px] break-words font-plus-jakarta-sans">
                            Record Pregnancy
                        </div>
                        <div className="text-[10px] 2xl:text-sm text-[#726C6C] font-plus-jakarta-sans">
                            Enter Pregnancy Information
                        </div>
                    </div>
                </OffcanvasHeader>
                <OffcanvasBody>
                    <form onSubmit={formik.handleSubmit}>
                        <FormGroup>
                            <FlexCol className="" gap={20}>
                                <Flex className="grid grid-cols-1 gap-[30px] items-center">
                                    {/* Insemination Type */}
                                    <FormInput
                                        title="Insemination Type"
                                        placeholder="Artificial"
                                        value={formik.values.inseminationType}
                                        change={(e) => formik.setFieldValue("inseminationType", e)}
                                      
                                        error={formik.touched.inseminationType && formik.errors.inseminationType}
                                    />

                                    {/* Insemination Date */}
                                    <DateSelect
                                        title="Insemination Date"
                                        placeholder="04 Oct 2024"
                                        value={formik.values.inseminationDate}
                                        change={(e) => formik.setFieldValue("inseminationDate", e)}
                                       
                                        error={formik.touched.inseminationDate && formik.errors.inseminationDate}
                                    />

                                    {/* Cattle ID */}
                                    <FormInput
                                        title="Cattle"
                                        placeholder="1865"
                                        value={formik.values.cattleId}
                                        change={(e) => formik.setFieldValue("cattleId", e)}
                                        
                                        error={formik.touched.cattleId && formik.errors.cattleId}
                                    />

                                    {/* Description 1 */}
                                    <TextArea
                                        title="Description"
                                        placeholder="Add Description"
                                        value={formik.values.description1}
                                        change={(e) => formik.setFieldValue("description1", e)}
                                        
                                        // error={formik.touched.description1 && formik.errors.description1}
                                    />

                                    {/* Confirmed Date */}
                                    <DateSelect
                                        title="Confirmed Date"
                                        placeholder="04 Oct 2024"
                                        value={formik.values.confirmedDate}
                                        change={(e) => formik.setFieldValue("confirmedDate", e)}
                                        
                                        error={formik.touched.confirmedDate && formik.errors.confirmedDate}
                                    />

                                    {/* Expected Delivery */}
                                    <DateSelect
                                        title="Expected Delivery"
                                        placeholder="04 Oct 2024"
                                        value={formik.values.expectedDelivery}
                                        change={(e) => formik.setFieldValue("expectedDelivery", e)}
                                        
                                        error={formik.touched.expectedDelivery && formik.errors.expectedDelivery}
                                    />
                                    <DropdownButton
                                      title="Severity"
                                      placeholder=""
                                      options={option}
                                      value={formik.values.serverity}
                                      change={(e) => formik.setFieldValue("serverity", e)}
                                    />
                                    {/* Description 2 */}
                                    <TextArea
                                        title="Description"
                                        placeholder="Add Description"
                                        value={formik.values.description2}
                                        change={(e) => formik.setFieldValue("description2", e)}
                                       
                                        error={formik.touched.description2 && formik.errors.description2}
                                    />
                                </Flex>
                            </FlexCol>
                        </FormGroup>
                        <div className=" flex justify-between mt-8 ">
                            <CommonButton
                                style={{ fontSize: "12px", backgroundColor: "none", color: "#726C6C", border: "1px #CCCBCB solid ", marginRight: "8px" }}
                                onClick={toggle}
                                outline
                                title='Discard'
                            />



                            <CommonButton title='Add Pregnancy' style={{ fontSize: "12px" }} />


                        </div>
                    </form>
                </OffcanvasBody>

            </FlexCol>
        </Offcanvas>
    )
}

export default AddPregnancy