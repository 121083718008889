import React from 'react';
import { Card, CardBody } from 'reactstrap';
import Chart from 'react-apexcharts';
import barchart from "../../assets/Images/Frame.svg" 
import ci from "../../assets/Images/ci_expand.svg"

const MilkReportChart = () => {
  const chartOptions = {
    chart: {
      type: 'bar',
      toolbar: { show: false },
    },
    xaxis: {
      categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul'],
      
    
     labels: {
        style: {
          colors: '#878787', // Set the color of month names
          fontSize: '12px',  // Set the font size
          fontFamily: 'Plus Jakarta Sans', // Set the font family
          fontWeight: '400', // Set the font weight
        },
      },
    },
    yaxis: {
        show: false, // This line removes the y-axis labels
      },
      grid: {
        show: false, // Remove horizontal grid lines
      },
    colors: ['#16A34A'],
    plotOptions: {
      bar: {
        borderRadius: 4,
        horizontal: false,
        columnWidth: '40%',
       
      },
    },
      stroke: {
        show: false, // This line removes the strokes
      },
      dataLabels: {
        enabled: false, // This line disables data labels
      },
    
    }
  const chartSeries = [
    {
      name: 'Milk',
      data: [123, 74, 54, 25, 63, 144, 158], // Replace with your data
    },
  ];

  return (
    <div className="  bg-white rounded-xl border border-gray-300 2xl:p-6 p-3">
      <CardBody>
        <div className="flex justify-between items-center mb-4">
          <div className="flex items-center gap-2">
            <div className="w-6 h-6  rounded mb-3" >
                <img src={barchart}>
                </img>
                </div>
            <h2 className="text-sm font-bold text-[#080808] font-plus-jakarta-sans">Monthly Milk Report</h2>
          </div>
          <div className="bg-white  rounded-md border border-gray-300   flex items-center w-14 h-7">
            {/* <div className="border border-gray-600 w-6 h-6" > */}
            <img src={ci} className='w-7 h-5'>
            </img>
                {/* </div> */}
         <p className='ml-1 mt-1'>  ...</p>
          </div>
        </div>

        {/* <div className="flex space-x-4 mb-6">
          {['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul'].map((month, index) => (
            <div key={index} className={`flex-1 p-2 text-center ${month === 'Jun' ? 'text-green-600' : 'text-gray-600'}`}>
              {month}
            </div>
          ))}
        </div> */}
        <div className="flex items-center">
            <div className="w-3 h-3 bg-green-600 rounded" />
            <span className="ml-2 text-gray-800 font-plus-jakarta-sans text-xs">Milk</span>
          </div>

        <Chart options={chartOptions} series={chartSeries} type="bar" height={250}  />

        {/* <div className="flex flex-col items-start mt-4">
          <div className="flex items-center">
            <div className="w-5 h-5 bg-green-600 rounded" />
            <span className="ml-2 text-gray-800">Milk</span>
          </div>
          <div className="bg-white shadow-sm rounded-md border border-gray-300 p-2 mt-2 flex items-center justify-center">
            <span className="text-lg font-bold">325 lr</span>
            <span className="text-gray-600 ml-2">+ 12%</span>
          </div>
        </div> */}
      </CardBody>
    </div>
  );
};

export default MilkReportChart;
