import React from 'react'
import { FormGroup, Offcanvas, OffcanvasBody, OffcanvasHeader } from 'reactstrap'
import FlexCol from '../Common/FlexCol'
import { Flex } from 'antd'
import DateSelect from '../Common/DateSelector'
import FormInput from '../Common/FormInput'
import TextArea from '../Common/TextArea'
import CommonButton from '../Common/Button'

function RecordDeworming({isOpen,toggle}) {
  return (
    <Offcanvas isOpen={isOpen} toggle={toggle} direction="end"  className="!w-[320px] 2xl:!w-[480px]  max-h-full overflow-y-auto"    >
    <FlexCol className={"p-4"} >

        <OffcanvasHeader >
            <div >
                <div className="text-[16px] font-semibold 2xl:text-2xl text-[#09090B]  leading-[21.6px] break-words font-plus-jakarta-sans">Record Deworming</div>
                <div className="text-[10px] 2xl:text-sm  text-[#726C6C] font-plus-jakarta-sans ">Provide Deworming data and follow-up.</div>
            </div>
        </OffcanvasHeader>
        <OffcanvasBody >
            <FormGroup>
                <FlexCol className={""} gap={20}>
                    <Flex className="grid grid-cols-1 gap-[30px] items-center">

                    <FormInput title='Medicine Name ' placeholder='Covid Vaccine'  />
                    <FormInput title='Dosage  ' placeholder='2.5 mg'  />
                        <DateSelect title='Deworming Date' placeholder='04 Oct 2024' />
                        <FormInput title='Cattle ' placeholder='1865'  />
                        <FormInput title='Health Status ' placeholder='Critical'  />
                        <DateSelect title='Next Deworming Date' placeholder='04 Oct 2024' />
                        <TextArea title='Description' placeholder='Add Description' />

                    </Flex>
                </FlexCol>
            </FormGroup>
            <div className=" flex justify-between mt-8 ">
                <CommonButton
                    style={{ fontSize: "12px", backgroundColor: "none", color: "#726C6C", border: "1px #CCCBCB solid ", marginRight: "8px" }}
                    onClick={toggle}
                    outline
                    title='Discard'
                />



                <CommonButton title='Record Deworming' style={{ fontSize: "12px" }} />


            </div>
        </OffcanvasBody>

    </FlexCol>
</Offcanvas>
  )
}

export default RecordDeworming