import React, { useEffect, useState } from "react";
import { LuLock } from "react-icons/lu";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { FiUser } from "react-icons/fi";
import { Modal, Button as AntButton } from "antd";
import FormInput from "../Common/FormInput";
import image1 from "../../../src/assets/Images/Rectangle 4.png";
import image2 from "../../../src/assets/Images/Rectangle 5.png";
import image3 from "../../../src/assets/Images/image-SZZIDz8I3-transformed__1_-removebg-preview (1) 1.png";
import logo from "../../../src/assets/Images/image 6.png";
import bgHome from "../../../src/assets/Images/Bg home.png"
import { Button } from "reactstrap";
import { useNavigate } from "react-router";
import API, { action } from "../../Api";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

export default function Login() {
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  // Validation schema using Yup
  const validationSchema = Yup.object().shape({
    username: Yup.string().required("Username is required"),
    password: Yup.string().required("Password is required"),
  });

  const handleSubmit = async (values, { setSubmitting, setErrors }) => {
    try {
      const result = await action(API.LOGIN, {
        email: values.username,
        password: values.password,
      });

      if (result && result.status && result.status.code === 200) {
        const accessToken = result.response.accessToken;
        const name = result.response.data.full_name;
        const email = result.response.data.email;
        const mobile = result.response.data.mobile;
        const userid = result.response.data.user_id;
        const modulePrivileges = result.response.data.role.module_privileges;

        localStorage.setItem('usertype', accessToken);
        localStorage.setItem('name', name);
        localStorage.setItem('email', email);
        localStorage.setItem('mobile', mobile);
        localStorage.setItem('userid', userid);
        localStorage.setItem('modulePrivileges', JSON.stringify(modulePrivileges));
        navigate("/"); // Redirect to the homepage
        window.location.reload();
      } else {
        const errorMessage = result?.response?.message || "Login failed. Please try again.";
        setErrors({ submit: errorMessage });
        toast.error(errorMessage, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    } catch (error) {
      console.error("Login failed", error);
      setErrors({ submit: "Login failed. Please try again." });
      toast.error("Login failed. Please try again.", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className="flex w-full h-screen">
      <ToastContainer />
      <div className="w-full lg:w-1/2 flex items-center justify-center bg-gray-100">
        <div className="w-full max-w-md p-8 mx-auto">
          <div className="flex justify-center lg:justify-start"></div>

          <div className="flex flex-col gap-6">
            <div className="text-center gap-1">
              <img src={logo} alt="overlay image" className="mx-auto" />
              <h1 className="text-xl font-bold 2xl:text-3xl">Welcome back!</h1>
              <p className="text-sm opacity-50 2xl:text-base font-normal">Sign in to Continue</p>
            </div>

            <Formik
              initialValues={{ username: "", password: "" }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ isSubmitting }) => (
                <Form className="flex flex-col gap-6">
                  <div className="relative flex items-center border border-opacity-20 rounded-2xl">
                    <div className="flex items-center px-4">
                      <FiUser size={20} />
                    </div>
                    <Field
                      type="text"
                      name="username"
                      className="w-100 h-12 text-xs bg-transparent border-0 outline-none px-3 font-normal"
                      placeholder="Username"
                    />
                    <ErrorMessage name="username" component="div" className="text-red-500 text-xs" />
                  </div>

                  <div className="relative flex items-center border border-opacity-20 rounded-2xl">
                    <div className="flex items-center px-4">
                      <LuLock size={20} />
                    </div>
                    <Field
                      type={showPassword ? "text" : "password"}
                      name="password"
                      className="w-full h-12 text-xs bg-transparent border-none outline-none px-3 font-normal bg-[#E3E3E3]"
                      placeholder="Password"
                    />
                    <div
                      className="absolute right-4 top-1/2 transform -translate-y-1/2 cursor-pointer"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? (
                        <FiEyeOff size={18} className="text-black opacity-50" />
                      ) : (
                        <FiEye size={18} className="text-black opacity-50" />
                      )}
                    </div>
                    <ErrorMessage name="password" component="div" className="text-red-500 text-xs" />
                  </div>

                  <div className="flex justify-center w-full">
                    <Button
                      color="success"
                      type="submit"
                      disabled={isSubmitting}
                      className="w-full h-12 text-xs text-white bg-gradient-to-r from-green-700 to-green-500"
                      style={{ borderRadius: '10px' }}
                    >
                      {isSubmitting ? "Logging in..." : "Login Now"}
                    </Button>
                  </div>

                  <div className="text-center mt-4">
                    <div className="text-sm text-black cursor-pointer hover:text-primary">
                      Forgot password?
                    </div>
                  </div>

                  <Modal
                    title="Forgot Password"
                    centered
                    footer={[
                      <AntButton key="cancel">Cancel</AntButton>,
                      <AntButton key="submit" type="primary">Submit</AntButton>,
                    ]}
                  >
                    <FormInput title="Email" placeholder="Email" required={true} />
                  </Modal>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>

      <div className="col-start-1 lg:flex lg:w-1/2 relative">
        <img
          src={bgHome}
          alt="background image"
          className="absolute inset-0 w-full h-full object-cover"
        />
         <div className="absolute top-[80px] left-0 right-0 flex justify-center w-96">
          <h2 className="text-white text-2xl md:text-3xl 2xl:text-3xl  font-bold  bg-opacity-50 p-4 rounded font-plus-jakarta-sans">
            Streamline Your Farm Management with Smart Solutions
          </h2>
        </div>
      </div>
    </div>

  );
}
