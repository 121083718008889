import React from 'react'
import cow from "../../assets/Images/cow.png";

function YieldingCattle() {
  return (
    <div className="w-full h-full p-3 bg-[#FAFAFA] rounded-xl border border-[#E3E3E3] flex flex-col items-start gap-2.5">
    <div className="self-stretch h-full flex flex-col justify-start items-start gap-4">
        <div className="self-stretch flex justify-between items-center">
            <div className="text-[#414D55] text-sm font-bold leading-[21.6px] font-plus-jakarta-sans">Top Yielding Cattle</div>
            <div className="text-[#898483] text-xs font-normal leading-[16.8px] font-plus-jakarta-sans">See All</div>
        </div>
        <div className="self-stretch h-full  flex flex-col justify-start items-start gap-4">
            {Array.from({ length: 4 }, (_, index) => (
                <div key={index} className="self-stretch p-2 bg-white rounded-lg border border-[#E3E3E3] flex justify-between items-center">
                    <div className="self-stretch flex justify-start items-start gap-5">
                        <div className="relative w-10 h-10">
                            <div className="absolute w-10 h-10 bg-[#16A34A] rounded-md" />
                            <img
                          className="absolute w-9 h-9 rounded-md top-0 left-0.5"
                          src={cow}
                        //   alt={item.text}
                        />
                        </div>
                        <div className="flex flex-col justify-start items-start gap-1">
                            <div className="self-stretch text-[#0A0B0A] text-sm font-bold leading-[19.2px] font-plus-jakarta-sans">Cattle No. 1856</div>
                            <div className="self-stretch text-[#898483] text-xs font-normal leading-[16.8px] font-plus-jakarta-sans">Last Recorded 46 mins ago</div>
                        </div>
                    </div>
                    <div className="flex items-center gap-1.5 p-2 bg-white  rounded-md border border-[#E0E0E0]">
                        <div className="text-[#080808] text-xs font-bold leading-[19.2px] font-plus-jakarta-sans">325 lr</div>
                        <div className="text-[#878787] text-xs font-bold leading-[19.2px] font-plus-jakarta-sans">+ 12%</div>
                    </div>
                </div>
            ))}
        </div>
    </div>
</div>

  )
}

export default YieldingCattle