import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup'; // Import Yup for validation
import FormInput from '../Common/FormInput';
import CommonButton from '../Common/Button';
import { Col, FormGroup, Modal, ModalBody, ModalHeader } from 'reactstrap';
import DateSelect from '../Common/DateSelector';
import TextArea from '../Common/TextArea';
import FlexCol from '../Common/FlexCol';
import API, { action } from '../../Api';
import { notification } from 'antd'; // Import Ant Design notification


function AddCattle({ isOpen, toggle, refresh }) {
    const [cattleType, setCattleType] = useState([]);
    const id = localStorage.getItem('userid');

    const formik = useFormik({
        initialValues: {
            cattleId: '',
            feedGroup: '',
            currentLactation: '',
            cattleWeight: '',
            expectedDailyMilking: '',
            lastCalvingDate: '',
            inseminationDate: '',
            pregnancyDate: '',
            additionalNotes: '',
            cattleType: '', // Added cattleType to Formik's state
            isInseminated: 'Yes', // Added isInseminated to Formik's state
            inseminationType: 'Artificial', // Added inseminationType to Formik's state
            isPregnant: 'No', // Added isPregnant to Formik's state
        },
        validationSchema: Yup.object({
            cattleId: Yup.string().required('Required'),
            // feedGroup: Yup.string().required('Required'),
            currentLactation: Yup.number().required('Required'),
            cattleWeight: Yup.string().required('Required'),
            expectedDailyMilking: Yup.string().required('Required'),
            // lastCalvingDate: Yup.date().required('Required'),
            inseminationDate: Yup.date(),
            pregnancyDate: Yup.date(),
            additionalNotes: Yup.string(),
            cattleType: Yup.string().required('Required'), // Validation for cattleType
            isInseminated: Yup.string().required('Required'), // Validation for isInseminated
            inseminationType: Yup.string().required('Required'), // Validation for inseminationType
            isPregnant: Yup.string().required('Required'), // Validation for isPregnant
        }),
        onSubmit: async (values) => {
            console.log(values, 'values');
            try {
                const result = await action(API.ADD_CATTLE, {
                    cattle_id: values.cattleId,
                    cattle_type: values.cattleType,
                    feed_type: 1,
                    lactation: values.currentLactation,
                    weight: values.cattleWeight,
                    exp_daily_milk: values.expectedDailyMilking,
                    notes: values.additionalNotes,
                    last_calving_date: values.lastCalvingDate,
                    dob: values.pregnancyDate,
                    created_by: id,
                });

                // Optionally reset the form or close the modal
                formik.resetForm();
                toggle(); // Close the modal
                console.log(result, 'code');

                // Check if the result is successful
                if (result.status.code === 200) {
                    notification.success({
                        message: 'Successfully added cattle',
                        description: 'The cattle has been successfully added.',
                        placement: "top",
                    });
                } else if (result && result.status && result.status.code === 409) {
                    notification.error({
                        message: 'Error',
                        description: result.response?.message || 'Failed to add cattle.',
                        placement: 'top',
                    });
                } else {
                    notification.error({
                        message: 'Failed to add cattle',
                        description: 'An unexpected error occurred.',
                        placement: 'top',
                    });
                }
            } catch (error) {
                console.error('Error adding cattle:', error);
                notification.error({
                    message: 'Error adding cattle',
                    description: error.response?.data?.response?.message || 'An error occurred.',
                    placement: 'topRight',
                });
            }
        },
    });

    const getcattleType = async () => {
        try {
            const result = await action(API.GET_CATTLETYPE);
            const roles = result.response.data.map(role => ({
                label: role.cattle_type_name, // Display name
                value: role.id, // Unique identifier
            }));
            setCattleType(roles); // Set data with both label and value
            console.log(roles); // Check if data is in correct format
        } catch (error) {
            console.error('Error fetching cattletype data:', error);
        }
    };

    useEffect(() => {
        getcattleType();
        console.log(cattleType, 'cattletype');
    }, []);

    useEffect(() => {
        if (!isOpen) {
            formik.resetForm();
        }
    }, [isOpen]);


    return (
        <div>
        <Modal isOpen={isOpen} toggle={toggle}>
            <div className='p-[40px]'>
                <div>
                    <div className="text-[16px] font-semibold 2xl:text-2xl text-[#09090B] leading-[21.6px] break-words font-plus-jakarta-sans">Cattle Registration</div>
                    <div className="text-[10px] 2xl:text-sm text-[#726C6C] font-plus-jakarta-sans">Add a New Cattle</div>
                </div>
            </div>

            <ModalBody>
                <form onSubmit={formik.handleSubmit}>
                    <FormGroup className="px-[32px]">
                    <FlexCol gap={32}>
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-[30px] items-center gap-y-[40px]">
                                <div className="flex flex-col justify-start gap-2">
                                    <div className="text-[#16A34A] text-[16px] md:text-[12px] 2xl:text-[16px] font-medium leading-[19.2px] break-words font-plus-jakarta-sans">
                                        Cattle Type
                                    </div>
                                    <div className="flex gap-2">
                                        {cattleType.map((type) => (
                                            <div
                                                key={type.value}
                                                onClick={() => formik.setFieldValue('cattleType', type.value)}
                                                className={`p-2 rounded-lg cursor-pointer ${formik.values.cattleType === type.value ? 'bg-[#E3E3E3]' : 'bg-transparent'} flex justify-center items-center`}
                                            >
                                                <div className="text-[#0A0B0A] text-[12px] md:text-[10px] font-medium leading-[16.8px] break-words font-plus-jakarta-sans">
                                                    {type.label}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                {/* <FormInput 
                                    title="Select Feed Group" 
                                    placeholder="Q1 TMR" 
                                    value={formik.values.feedGroup} 
                                    change={(e) => formik.setFieldValue("feedGroup", e)} 
                                    error={formik.touched.feedGroup && formik.errors.feedGroup} 
                                /> */}
                            </div>

                            <div className="grid grid-cols-1 md:grid-cols-2 gap-[30px] items-center">
                                <FormInput
                                    title="Cattle ID"
                                    placeholder="Cattle 3256"
                                    value={formik.values.cattleId}
                                    change={(e) => formik.setFieldValue("cattleId", e)}
                                    error={formik.touched.cattleId && formik.errors.cattleId} 
                                />
                                <FormInput 
                                    title="Current Lactation" 
                                    placeholder="2"
                                    type='number' 
                                    value={formik.values.currentLactation} 
                                    change={(e) => formik.setFieldValue("currentLactation", e)} 
                                    error={formik.touched.currentLactation && formik.errors.currentLactation} 
                                />
                            </div>

                            <div className="grid grid-cols-1 md:grid-cols-2 gap-[30px] items-center">
                                <FormInput 
                                    title="Cattle Weight" 
                                    placeholder="563 kg"
                                    type='number' 
                                    value={formik.values.cattleWeight} 
                                    change={(e) => formik.setFieldValue("cattleWeight", e)} 
                                    error={formik.touched.cattleWeight && formik.errors.cattleWeight} 
                                />
                                <FormInput 
                                    title="Expected Daily Milking" 
                                    placeholder="1.5 L"
                                    type='number'    
                                    value={formik.values.expectedDailyMilking} 
                                    change={(e) => formik.setFieldValue("expectedDailyMilking", e)} 
                                    error={formik.touched.expectedDailyMilking && formik.errors.expectedDailyMilking} 
                                />
                            </div>

                            <div className="grid grid-cols-1 md:grid-cols-2 gap-[30px] items-center">
                                <DateSelect 
                                    title="Last Calving Date" 
                                    placeholder="04 Oct 2024" 
                                    value={formik.values.lastCalvingDate} 
                                    change={(e) => formik.setFieldValue("lastCalvingDate", e)} 
                                   
                                />
                                <div className="flex flex-col justify-start gap-2">
                                    <div className="text-[#16A34A] text-[16px] md:text-[12px] 2xl:text-[16px] font-medium leading-[19.2px] break-words font-plus-jakarta-sans">
                                        Is Cattle Inseminated
                                    </div>
                                    <div className="flex gap-2">
                                        {['Yes', 'No'].map((tab) => (
                                            <div
                                                key={tab}
                                                onClick={() => formik.setFieldValue('isInseminated', tab)} // Update Formik state
                                                className={`p-2 rounded-lg cursor-pointer ${formik.values.isInseminated === tab ? 'bg-[#E3E3E3]' : 'bg-transparent'} flex justify-center items-center`}
                                            >
                                                <div className="text-[#0A0B0A] text-[12px] md:text-[10px] font-medium leading-[16.8px] break-words font-plus-jakarta-sans">
                                                    {tab}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>

                            <div className="grid grid-cols-1 md:grid-cols-2 gap-[30px] items-center">
                                <div className="flex flex-col justify-start gap-2">
                                    <div className="text-[#16A34A] text-[16px] md:text-[12px] 2xl:text-[16px] font-medium leading-[19.2px] break-words font-plus-jakarta-sans">
                                        Insemination Type
                                    </div>
                                    <div className="flex gap-2">
                                        {['Artificial', 'Natural'].map((tab) => (
                                            <div
                                                key={tab}
                                                onClick={() => formik.setFieldValue('inseminationType', tab)} // Update Formik state
                                                className={`p-2 rounded-lg cursor-pointer ${formik.values.inseminationType === tab ? 'bg-[#E3E3E3]' : 'bg-transparent'} flex justify-center items-center`}
                                            >
                                                <div className="text-[#0A0B0A] text-[12px] md:text-[10px] font-medium leading-[16.8px] break-words font-plus-jakarta-sans">
                                                    {tab}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div className="flex flex-col justify-start gap-2">
                                    <div className="text-[#16A34A] text-[16px] md:text-[12px] 2xl:text-[16px] font-medium leading-[19.2px] break-words font-plus-jakarta-sans">
                                        Is Cattle Pregnant
                                    </div>
                                    <div className="flex gap-2">
                                        {['Yes', 'No'].map((tab) => (
                                            <div
                                                key={tab}
                                                onClick={() => formik.setFieldValue('isPregnant', tab)} // Update Formik state
                                                className={`p-2 rounded-lg cursor-pointer ${formik.values.isPregnant === tab ? 'bg-[#E3E3E3]' : 'bg-transparent'} flex justify-center items-center`}
                                            >
                                                <div className="text-[#0A0B0A] text-[12px] md:text-[10px] font-medium leading-[16.8px] break-words font-plus-jakarta-sans">
                                                    {tab}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>

                            <div className="grid grid-cols-1 md:grid-cols-2 gap-[30px] items-center">
                                <DateSelect 
                                    title="Insemination Date" 
                                    placeholder="04 Oct 2024" 
                                    value={formik.values.inseminationDate} 
                                    change={(e) => formik.setFieldValue("inseminationDate", e)} 
                                    error={formik.touched.inseminationDate && formik.errors.inseminationDate} 
                                />
                                <DateSelect 
                                    title="Expected Pregnancy Date" 
                                    placeholder="04 Oct 2024" 
                                    value={formik.values.pregnancyDate} 
                                    change={(e) => formik.setFieldValue("pregnancyDate", e)} 
                                    error={formik.touched.pregnancyDate && formik.errors.pregnancyDate} 
                                />
                            </div>

                            <TextArea
                                title="Additional Notes"
                                placeholder="Write any additional notes here..."
                                value={formik.values.additionalNotes}
                                change={(e) => formik.setFieldValue("additionalNotes", e)}
                                error={formik.touched.additionalNotes && formik.errors.additionalNotes}
                            />

                            <Col className="flex justify-end mt-5 gap-2">
                                <CommonButton title="Save" type="submit" />
                                <CommonButton title="Cancel" type="button" onClick={toggle} />
                            </Col>
                        </FlexCol>
                    </FormGroup>
                </form>
            </ModalBody>
        </Modal>
       
        </div>  
    );
}

export default AddCattle;
