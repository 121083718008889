import React from 'react';
import { BiInjection } from 'react-icons/bi';
import { CiUser } from 'react-icons/ci';
import { IoIosHeartEmpty } from "react-icons/io";
import { LuActivitySquare, LuMilk } from 'react-icons/lu';
import { MdDashboard, MdHealthAndSafety, MdOutlineInsertChart } from 'react-icons/md';
import { PiCowLight } from 'react-icons/pi';
import { RiLeafFill } from 'react-icons/ri';
import { useLocation } from 'react-router';

const SideBar = ({ isOpen, onClose }) => {
    const location = useLocation();
    
    // Retrieve module privileges from local storage
    const modulePrivileges = JSON.parse(localStorage.getItem('modulePrivileges')) || [];

    const menuItems = [
        { title: 'Dashboards', icon: <MdDashboard />, path: '/', privilegeId: 1 }, // No privilege needed
        { title: 'Cattle Board', icon: <PiCowLight />, path: '/cattleDashboard', privilegeId: 6 },
        { title: 'Breeding', icon: <IoIosHeartEmpty />, path: '/breeding', privilegeId: 3 },
        { title: 'Feed Inventory', icon: <RiLeafFill />, path: '/feedInventory', privilegeId: 8 },
        { title: 'Milk Report', icon: <LuMilk />, path: '/milkReport', privilegeId:4  },
        { title: 'Vaccination', icon: <BiInjection />, path: '/vaccination', privilegeId: 5  },
        { title: 'Health Monitoring', icon: <MdHealthAndSafety />, path: '/healthMonitoring', privilegeId: 2 },
        { title: 'Activity Log', icon: <LuActivitySquare />, path: '/activityLog', privilegeId: 7 },
        { title: 'User Management', icon: <CiUser />, path: '/userManagement', privilegeId: 10 },
        { title: 'Reports Monitoring', icon: <MdOutlineInsertChart />, path: '/reportsMonitoring', privilegeId: 9  },
    ];

    // Filter menu items based on module privileges, but always include the dashboard
    const filteredMenuItems = menuItems.filter(item => 
        item.privilegeId === null || modulePrivileges.includes(item.privilegeId)
    );

    return (
        <div>
            {/* Sidebar for mobile screens */}
            <div className={`fixed top-8 left-0 w-64 h-full bg-white border-r border-[#E3E3E3] text-gray-600 overflow-y-auto py-8 px-1 font-plusJakartaSans font-bold z-50 transform ${isOpen ? 'translate-x-0' : '-translate-x-full'} transition-transform duration-300 ease-in-out lg:hidden`}>
                <div className="flex flex-col gap-3 p-4">
                    {filteredMenuItems.map((item) => (
                        <a
                            key={item.title}
                            href={item.path}
                            className={`flex items-center py-3 px-4 hover:bg-[#DBFAE780] hover:text-[#16A34A] hover:border-l-[1px] hover:border-[#16A34A] transition duration-200 text-[#656565] no-underline ${location.pathname === item.path ? 'bg-[#DBFAE780] text-[#16A34A]' : ''}`}
                        >
                            <span className="mr-2">{item.icon}</span>
                            <span>{item.title}</span>
                        </a>
                    ))}
                </div>
            </div>

            {/* Sidebar for larger screens */}
            <div className="fixed top-16 left-0 w-72 md:w-56 sm:w-48 2xl:w-72 h-[calc(100%-70px)] bg-white border-r border-[#E3E3E3] text-gray-600 overflow-y-auto py-8 px-2 font-plusJakartaSanstext-lg md:text-sm sm:text-sm 2xl:text-xl font-bold hidden md:block">
                <div className="flex flex-col !gap-3" style={{ borderRadius: "4px" }}>
                    {filteredMenuItems.map((item) => (
                        <a
                            key={item.title}
                            href={item.path}
                            className={`flex items-center !py-3 !px-4 hover:bg-[#DBFAE780] hover:text-[#16A34A] hover:border-l-[2px] hover:border-[#16A34A] transition duration-200 text-[#656565] no-underline ${location.pathname === item.path ? 'bg-[#DBFAE780] !text-[#16A34A] !border-l-1 !border-[#16A34A] hover:border-l-1' : ''}`}
                        >
                            <span className="mr-2">{item.icon}</span>
                            <span>{item.title}</span>
                        </a>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default SideBar;
