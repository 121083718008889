import React, { useState } from 'react'
import { FormGroup, Offcanvas, OffcanvasBody, OffcanvasHeader } from 'reactstrap'
import FlexCol from '../Common/FlexCol'
import { Flex } from 'antd'
import DateSelect from '../Common/DateSelector'
import FormInput from '../Common/FormInput'
import TextArea from '../Common/TextArea'
import CommonButton from '../Common/Button'

export default function RecordMedication({ isOpen, toggle }) {
    const [activeTab, setActiveTab] = useState('Morning');
    const tabs = ['Morning', ' Noon', 'Evening','All'];
  return (
    <Offcanvas isOpen={isOpen} toggle={toggle} direction="end"  className="!w-[320px] 2xl:!w-[480px]  max-h-full overflow-y-auto"    >
    <FlexCol className={"p-4"} >

        <OffcanvasHeader >
            <div >
                <div className="text-[16px] font-semibold 2xl:text-2xl text-[#09090B]  leading-[21.6px] break-words font-plus-jakarta-sans">Record Medication</div>
                <div className="text-[10px] 2xl:text-sm   text-[#726C6C] font-plus-jakarta-sans ">Add dosage and treatment details.</div>
            </div>
        </OffcanvasHeader>
        <OffcanvasBody >
            <FormGroup>
                <FlexCol className={""} gap={20}>
                    <Flex className="grid grid-cols-1 gap-[30px] items-center">
                    <FormInput title='Medicine Name  ' placeholder='Covid Vaccine' className='"text-green-600 font-semibold font-plus-jakarta-sans' />
                        <div style={{ flexDirection: 'column', justifyContent: 'flex-start', gap: 14, display: 'flex' }}>
                            <div className="text-[#16A34A] text-[16px] md:text-[12px] 2xl:text-[16px] font-medium leading-[19.2px] break-words font-plus-jakarta-sans">
                            Medicine Slot
                            </div>
                            <div className="flex gap-2">

                                {tabs.map((tab) => (
                                    <div
                                        key={tab}
                                        onClick={() => setActiveTab(tab)}
                                        className={`p-2 rounded-lg cursor-pointer ${activeTab === tab ? 'bg-[#E3E3E3]' : 'bg-transparent'} flex justify-center items-center`}
                                    >
                                        <div className={`text-[#0A0B0A] text-[12px] md:text-[10px]  font-medium leading-[16.8px] break-words`}>
                                            {tab}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <FormInput title='Dosage  ' placeholder='2.5 mg'  />
                        <DateSelect title='Medication Date' placeholder='04 Oct 2024' />
                        <FormInput title='Cattle  ' placeholder='1865'  />
                         <FormInput title='Health Status  ' placeholder='Critical'  />
                        <DateSelect title='Next Medication Date' placeholder='04 Oct 2024' />
                       
                       <TextArea title='Description' placeholder='Add Description'/>

                    </Flex>
                </FlexCol>
            </FormGroup>
            <div className=" flex justify-between mt-8 ">
                <CommonButton
                    style={{ fontSize: "12px", backgroundColor: "none", color: "#726C6C", border: "1px #CCCBCB  solid ", marginRight: "8px" }}
                    onClick={toggle}
                    outline
                    title='Discard'
                />



                <CommonButton title='Save' style={{ fontSize: "12px" }} />


            </div>
        </OffcanvasBody>

    </FlexCol>
</Offcanvas>
  )
}
