import React, { useState } from 'react';
import FormInput from '../Common/FormInput';
import CommonButton from '../Common/Button';
import TextArea from '../Common/TextArea';
import DateSelect from '../Common/DateSelector';
import { Flex } from 'antd';
import FlexCol from '../Common/FlexCol';
import { FormGroup, Offcanvas, OffcanvasBody, OffcanvasHeader } from 'reactstrap';

function RecordDelivery({ isOpen, toggle }) {
    const [calves, setCalves] = useState([{ id: '', weight: '', gender: 'Male' }]); // Array to hold calf details
    const tabs = ['Male', 'Female'];

    const handleAddCalf = () => {
        setCalves([...calves, { id: '', weight: '', gender: 'Male' }]); // Add a new calf with default values
    };

    const handleInputChange = (index, field, value) => {
        const updatedCalves = [...calves];
        updatedCalves[index][field] = value; // Update specific calf field
        setCalves(updatedCalves);
    };

    return (
        <Offcanvas isOpen={isOpen} toggle={toggle} direction="end"  className="!w-[320px] 2xl:!w-[480px]  max-h-full overflow-y-auto" >
            <FlexCol className={"p-4"}>
                <OffcanvasHeader>
                    <div>
                        <div className="text-[16px] font-semibold 2xl:text-2xl text-[#09090B] leading-[21.6px] break-words font-plus-jakarta-sans">Record Delivery</div>
                        <div className="text-[10px] 2xl:text-sm text-[#726C6C] font-plus-jakarta-sans">Add delivery data and notes.</div>
                    </div>
                </OffcanvasHeader>
                <OffcanvasBody>
                    <FormGroup>
                        <FlexCol className={""} gap={20}>
                            <Flex className="grid grid-cols-1 gap-[30px] items-center">
                                <DateSelect title='Delivery Date' placeholder='04 Oct 2024' />

                                {calves.map((calf, index) => (
                                    <div key={index} style={{ flexDirection: 'column', justifyContent: 'flex-start', gap: 20, display: 'flex' }}>
                                        <div className="text-[#16A34A] text-[16px] md:text-[12px] 2xl:text-[16px] font-medium leading-[19.2px] break-words font-plus-jakarta-sans">
                                            Calf Gender
                                        </div>
                                        <div className="flex gap-2">
                                            {tabs.map((tab) => (
                                                <div
                                                    key={tab}
                                                    onClick={() => handleInputChange(index, 'gender', tab)} // Set the gender for this calf
                                                    className={`p-2 rounded-lg cursor-pointer ${calf.gender === tab ? 'bg-[#E3E3E3]' : 'bg-transparent'} flex justify-center items-center`}
                                                >
                                                    <div className={`text-[#0A0B0A] text-[12px] md:text-[10px] font-medium leading-[16.8px] break-words font-plus-jakarta-sans`}>
                                                        {tab}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                        <FormInput
                                            title='New Calf Id'
                                            placeholder='1564'
                                            value={calf.id}
                                            onChange={(e) => handleInputChange(index, 'id', e.target.value)} // Update calf ID
                                        />
                                        <FormInput
                                            title='Calf Weight'
                                            placeholder='35 Kg'
                                            value={calf.weight}
                                            onChange={(e) => handleInputChange(index, 'weight', e.target.value)} // Update calf weight
                                        />
                                    </div>
                                ))}

                                <CommonButton title='Add Another Calf' style={{ fontSize: "10px" }} onClick={handleAddCalf} />
                                <TextArea title='Description' placeholder='Add Description' />
                            </Flex>
                        </FlexCol>
                    </FormGroup>
                    <div className="flex justify-between mt-8">
                        <CommonButton
                            style={{ fontSize: "12px", backgroundColor: "none", color: "#726C6C", border: "1px #CCCBCB solid ", marginRight: "8px" }}
                            onClick={toggle}
                            outline
                            title='Discard'
                        />
                        <CommonButton title='Save Delivery' style={{ fontSize: "12px" }} />
                    </div>
                </OffcanvasBody>
            </FlexCol>
        </Offcanvas>
    );
}

export default RecordDelivery;
