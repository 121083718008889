import React, { useState } from 'react';
import FlexCol from '../Common/FlexCol';
import CommonButton from '../Common/Button';
import Cards from '../Common/Cards';
import { Col, Row } from 'reactstrap';
import RecordMilk from '../Common/RecordMilk';
import YieldingCattle from '../Common/YieldingCattle';
import MilkReportChart from './MilkReportChart';
import DailyMilkReport from './DailyMilkReport';
import MilkingStatistics from './MilkingStatistics';
import Heading from '../Common/Heading';

function MilkReport() {
  const [showMilkingStats, setShowMilkingStats] = useState(false);

  const handleButtonClick = () => {
    setShowMilkingStats(true);
  };

  return (
    <div style={{ backgroundColor: "#FAFAFA" }}>
      <FlexCol>
        {!showMilkingStats ? ( // Conditional rendering
          <>
            <div className="d-flex justify-content-between">

              <Heading title='Milk Report' />
              <div className="d-flex space-x-5">
                <CommonButton
                  title="View All Records"
                  style={{ color: "#0EA5E9", backgroundColor: "none", border: "1px #0EA5E9 solid" }}
                  onClick={handleButtonClick} // Add onClick handler
                />
              </div>
            </div>

            <Cards />
            <Row className="gx-3 gy-4">
              <Col xl={8}>
                <div >
                  <DailyMilkReport />
                </div>
              </Col>
              <Col xl={4}>
                <RecordMilk />
              </Col>
              <Row className="gx-3 gy-3">
                <Col xl={5}>
                  <MilkReportChart />
                </Col>
                <Col xl={7}>
                  <YieldingCattle />
                </Col>
              </Row>
            </Row>
          </>
        ) : (
          <MilkingStatistics /> // Show MilkingStatistics when button is clicked
        )}
      </FlexCol>
    </div>
  );
}

export default MilkReport;
