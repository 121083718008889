import React, { useEffect, useState } from 'react';
import { FormGroup, Offcanvas, OffcanvasBody, OffcanvasHeader } from 'reactstrap';
import FormInput from '../Common/FormInput';
import CommonButton from '../Common/Button';
import FlexCol from '../Common/FlexCol';
import { Flex } from 'antd';
import { useFormik } from 'formik';
import * as Yup from 'yup'; // You can use Yup for validation
import API, { action } from '../../Api';
import DropdownButton from '../Common/DropdownButton';
import { notification } from 'antd';

function CreateUser({ isOpen, toggle, selectedUserId }) {
    const [id, setId] = useState(selectedUserId);

    useEffect(() => {
        setId(selectedUserId);
    }, [selectedUserId]);

    useEffect(() => {
        if (selectedUserId && selectedUserId.length > 0) {
            formik.setFieldValue("userName", selectedUserId[0]?.UserID);
            formik.setFieldValue("userRole", selectedUserId[0]?.Role);
            formik.setFieldValue("email", selectedUserId[0]?.email);
        }
    }, [selectedUserId]);

    const [roledata, setRoledata] = useState([]);
    const formik = useFormik({
        initialValues: {
            userName: '',
            email: '',
            userRole: '',
            password: '',
            rePassword: '',
            id: '',
        },
        validationSchema: Yup.object({
            userName: Yup.string().required('User Name is required'),
            email: Yup.string().required('Email is required'),
            userRole: Yup.string().required('User Role is required'),
            password: id ? Yup.string() : Yup.string().required('Password is required'),
            rePassword: id
                ? Yup.string()
                : Yup.string()
                    .oneOf([Yup.ref('password'), null], 'Passwords must match')
                    .required('Re-enter Password is required'),
        }),
        onSubmit: async (values) => {
            try {
                if (id) {
                    await action(API.UPDATE_USER, {
                        id: selectedUserId[0].userId,
                        full_name: values.userName,
                        privileges: values.permissions,
                        role_id: values.userRole,
                        email: values.email,
                    });
                    notification.success({ message: "User updated successfully!", placement: "top", });
                } else {
                    await action(API.CREATE_USER, {
                        full_name: values.userName,
                        password: values.password,
                        role_id: values.userRole,
                        email: values.email,
                    });
                    notification.success({ message: "User created successfully!", placement: "top", });
                }
                formik.resetForm();
                toggle();
            } catch (error) {
                console.error(error);
                notification.error({ message: "Something went wrong! Please try again.", placement: "top", });
            }
        },
    });

    const getRoleList = async () => {
        try {
            const result = await action(API.GET_ROLE);
            const roles = result.response.data.map(role => ({
                label: role.role_name,
                value: role.id,
            }));
            setRoledata(roles);
        } catch (error) {
            console.error("Error fetching role data:", error);
        }
    };

    useEffect(() => {
        getRoleList();
    }, []);

    const customToggle = () => {
        setId(null);
        formik.resetForm();
        toggle();
    };

    return (
        <Offcanvas isOpen={isOpen} toggle={customToggle} direction="end" className="!w-[320px] 2xl:!w-[480px] max-h-full overflow-y-auto">
            <FlexCol className="p-4">
                <OffcanvasHeader>
                    <div>
                        <div className="text-[16px] font-semibold 2xl:text-2xl text-[#09090B] leading-[21.6px] break-words font-plus-jakarta-sans">
                            {id ? " User Updation" : " User Creation"}
                        </div>
                        <div className="text-[10px] 2xl:text-sm text-[#726C6C] font-plus-jakarta-sans">
                            Enter user details and permissions.
                        </div>
                    </div>
                </OffcanvasHeader>
                <OffcanvasBody>
                    <form onSubmit={formik.handleSubmit}>
                        <FormGroup>
                            <FlexCol gap={20}>
                                <Flex className="grid grid-cols-1 gap-[30px] items-center">
                                    <FormInput
                                        title="User Name"
                                        placeholder="User Name"
                                        value={formik.values.userName}
                                        change={(e) => formik.setFieldValue("userName", e)}
                                        error={formik.touched.userName && formik.errors.userName}
                                    />
                                    <FormInput
                                        title="Email"
                                        placeholder="Enter Email"
                                        value={formik.values.email}
                                        change={(e) => formik.setFieldValue("email", e)}
                                        error={formik.touched.userName && formik.errors.email}
                                    />
                                    <DropdownButton
                                        title="User Role"
                                        placeholder="User role"
                                        value={formik.values.userRole}
                                        change={(e) => formik.setFieldValue("userRole", e)}
                                        error={formik.touched.userRole && formik.errors.userRole}
                                        options={roledata}
                                    />
                                    {!id && (
                                        <>
                                            <FormInput
                                                title="Enter Password"
                                                placeholder="Password"
                                                type="password"
                                                value={formik.values.password}
                                                change={(e) => formik.setFieldValue("password", e)}
                                                error={formik.touched.password && formik.errors.password}
                                            />
                                            <FormInput
                                                title="Re Enter Password"
                                                placeholder="Password"
                                                type="password"
                                                value={formik.values.rePassword}
                                                change={(e) => formik.setFieldValue("rePassword", e)}
                                                error={formik.touched.rePassword && formik.errors.rePassword}
                                            />
                                        </>
                                    )}
                                </Flex>
                            </FlexCol>
                        </FormGroup>
                        <div className="fixed bottom-0 left-0 right-0 flex justify-end p-4">
                            <CommonButton
                                style={{ backgroundColor: "none", color: "#726C6C", border: "1px #CCCBCB solid", marginRight: "8px", fontSize: "12px" }}
                                onClick={toggle}
                                outline
                                title='Cancel'
                            />
                            <CommonButton title='Create user' style={{ fontSize: "12px" }} type="submit" />
                        </div>
                    </form>
                </OffcanvasBody>
            </FlexCol>
        </Offcanvas>
    );
}

export default CreateUser;
