import React, { useEffect, useState } from 'react'
import ReactApexChart from 'react-apexcharts';
import { Card, CardBody } from 'reactstrap';

const PieChart = ({ title }) => {

    const series = [60, 20, 20]; // Values in percentage

    var options = {
        chart: {
            height: 300,
            type: 'pie',
        },
        labels: ['Team A', 'Team B', 'Team C'],
        legend: {
            position: 'right'
        },
        dataLabels: {
            enabled: true,
            formatter: function (val, opts) {
                return val.toFixed(0) + "%"; // Display the percentage
            },
            style: {
                fontSize: '14px',
                fontWeight: 'bold',
                colors: ['#fff'], // Label color
            },
            background: {
                enabled: true,
                foreColor: '#000',
               marginTop:10,
               marginLeft:10,
               width:16,
               height:16,
              
                borderRadius: '25%',  // Make the background circular
                borderWidth: 1,
                borderColor: '#fff',
                opacity: 1,
            },
            dropShadow: {
                enabled: true,
                top: 2,
                left: 2,
                blur: 4,
                opacity: 0.25
            },
            offsetY: -20, // Move the label upwards
            offsetX: 0,
        },
        plotOptions: {
            pie: {
                dataLabels: {
                    offset: 30, // Adjust this to move the labels outside of the pie
                }
            }
        },
        fill: {
            type: 'solid',
        }
    };

   

    return (
        <React.Fragment>
             <div className="2xl:p-6 p-2  bg-white rounded-xl border border-gray-300">
                <CardBody className="p-0 pb-2">
                    <div className="w-100">
                        <div className="border-0 align-items-center d-flex ml-5">
                            <h4 className="card-title mb-0 flex-grow-1 mt-2 2xl:text-[20px] text-[14px]" style={{
                                color: "#495057",
                                fontFamily: "Plus Jakarta Sans",
                                
                                fontStyle: "normal",
                                fontWeight: 700,
                                lineHeight: "normal"
                            }}>{title}</h4>
                        </div>
                        <div className="chart-container" style={{ position: "relative", marginTop: "16px" }}>
                            <ReactApexChart
                                dir="ltr"
                                className="apex-charts"
                                series={series}
                                options={options}
                                type="donut"
                                height={370}
                            />
                        </div>
                    </div>
                </CardBody>
            </div>
        </React.Fragment>
    )
}

export default PieChart;
