import React, { useState } from 'react';
import CommonButton from '../../Common/Button';
import Basicinformation from '../../Drawer/Basicinformatiom';

function BasicInformation({ Selected }) {
    const [datails, setDetails] = useState(false)

    const handleclick = () => setDetails(!datails)
    console.log(Selected.id, "details");

    return (
        <div className="basic-info-container">
            <div className="flex justify-between items-center">
                <p className="text-[#16A34A] font-plus-jakarta-sans text-sm  font-semibold leading-6">
                    Enrollment
                </p>
                <CommonButton title='Edit' style={{ fontSize: '12px', borderRadius: 10, backgroundColor: "#E3E3E3", color: "#2B2928", border: "none" }} onClick={handleclick} />
            </div>
            <div className="text-[#726C6C] font-plus-jakarta-sans text-xs font-semibold  flex flex-row items-center ">
                <div>
                    <p><strong>Cattle ID</strong></p>
                    <p><strong>Cattle Type</strong></p>
                    <p><strong>Date of Birth</strong></p>
                    <p><strong>Gender</strong></p>
                </div>
                <div className="ml-10 text-[#0A0B0A] capitalize">
                    <p>{Selected.cattle_id ? Selected.cattle_id : '--'}</p>
                    <p>{Selected.CattleType?.cattle_type_name ? Selected.CattleType?.cattle_type_name : '--'}</p>
                    <p>{Selected.dob ? Selected.dob : '--'}</p>
                    <p>{Selected.gender ? Selected.gender : '--'}</p>
                </div>
            </div>
            <div className="flex justify-between items-center mt-8">
                <p className="text-[#16A34A] font-plus-jakarta-sans text-sm font-semibold leading-6">
                    Family Breed and Genetics
                </p>
                {/* <CommonButton title='Edit' style={{ fontSize: '12px', borderRadius: 10, backgroundColor: "#E3E3E3", color: "#2B2928", border: "none" }} /> */}
            </div>
            <div className="text-[#726C6C] font-plus-jakarta-sans text-xs font-semibold  flex flex-row items-center ">
                <div>
                    <p><strong>Fathered By</strong></p>
                    <p><strong>Mothered By</strong></p>
                </div>
                <div className="ml-10 text-[#0A0B0A] capitalize">
                    <p>{Selected.father ? Selected.father : '--'}</p>
                    <p>{Selected.mother ? Selected.mother : '--'}</p>
                </div>
            </div>
            <div className="flex justify-between items-center mt-8">
                <p className="text-[#16A34A] font-plus-jakarta-sans text-sm font-semibold leading-6">
                    Additional Details
                </p>
                {/* <CommonButton title='Edit' style={{ fontSize: '12px', borderRadius: 10, backgroundColor: "#E3E3E3", color: "#2B2928", border: "none" }} /> */}
            </div>
            <div className="text-[#726C6C] font-plus-jakarta-sans text-xs font-semibold  flex flex-row items-center capitalize">
                <div>
                    <p><strong>License Information</strong></p>
                    <p><strong>Cooperation Number</strong></p>
                </div>
                <div className="ml-10 text-[#0A0B0A] capitalize">
                    <p>{Selected.licence_info ? Selected.licence_info : '--'}</p>
                    <p>{Selected.corporation_no ? Selected.corporation_no : '--'}</p>
                </div>
            </div>
            <Basicinformation isOpen={datails} toggle={handleclick} details={Selected} />
        </div>
    );
}

export default BasicInformation;
