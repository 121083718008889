// import React from 'react';
// import { Card, CardBody } from 'reactstrap';
// import Chart from 'react-apexcharts';
// import barchart from "../../assets/Images/Frame.svg"; 
// import ci from "../../assets/Images/ci_expand.svg";

// const DailyMilkReport = () => {
//   const chartOptions = {
//     chart: {
//       type: 'bar',
//       toolbar: { show: false },
//     },
//     xaxis: {
//       categories: ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'],
//       labels: {
//         style: {
//           colors: '#878787',
//           fontSize: '12px',
//           fontFamily: 'Plus Jakarta Sans',
//           fontWeight: '400',
//         },
//       },
//     },
//     yaxis: {
//       min: 0,
//       max: 600,
//       labels: {
//         show: false,
//       },
//     },
//     grid: {
//       show: false,
//     },
//     plotOptions: {
//       bar: {
//         borderRadius: 0,
//         horizontal: false,
//         columnWidth: '80%',
//         endingShape: 'flat',
//       },
//     },
//     colors: ['#16A34A'], // This can be left for stroke color
//     dataLabels: {
//       enabled: false,
//     },
//     stroke: {
//       show: true,
//       width: 1,
//       colors: ['#E0E0E0 '],
//     },
//     fill: {
//       type: 'gradient',
//       gradient: {
//         type: 'vertical',
//         shadeIntensity: 1,
//         gradientToColors: ['#ffffff'], // Ending color of the gradient
//         inverseColors: false,
//         opacityFrom: 1.25,
//         opacityTo: 0,
//         stops: [0, 100],
//       },
//     },
//   };

//   const chartSeries = [
//     {
//       name: 'Litre',
//       data: [256, 156, 136, 556, 132, 156, 224],
//     },
//   ];

//   return (
//     <Card className="w-full h-full relative bg-white rounded-lg border border-gray-300">
//       <CardBody>
//         <div className="flex justify-between items-center mb-4">
//           <div className="flex items-center gap-2">
//             <div className="w-6 h-6 rounded mb-3">
//               <img src={barchart} alt="Chart Icon" />
//             </div>
//             <h2 className="text-sm font-bold">Monthly Milk Report</h2>
//           </div>
//           <div className="bg-white rounded-md border border-gray-300 flex items-center w-14 h-7">
//             <img src={ci} className='w-7 h-5' alt="Expand Icon" />
//             <p className='ml-1 mt-1'>...</p>
//           </div>
//         </div>

        

//         <div className="relative h-[350px]">
//           <Chart options={chartOptions} series={chartSeries} type="bar" height="100%" />
//         </div>
//       </CardBody>
//     </Card>
//   );
// };

// export default DailyMilkReport;

import React from "react";
import { BarChart, Bar, XAxis, YAxis, ResponsiveContainer } from "recharts";
import { Card, CardBody } from 'reactstrap';
import barchart from "../../assets/Images/Frame.svg"; 
import ci from "../../assets/Images/ci_expand.svg";

const data = [
  { day: "SUN", litre: 256 },
  { day: "MON", litre: 156 },
  { day: "TUE", litre: 136 },
  { day: "WED", litre: 556 },
  { day: "THU", litre: 132 },
  { day: "FRI", litre: 156 },
  { day: "SAT", litre: 224 },
];

const DailyMilkReport = () => {
  return (
    <div className="  bg-white rounded-xl border border-gray-300 2xl:p-6 p-3">
      <CardBody>
        <div className="flex justify-between items-center mb-4">
          <div className="flex items-center gap-2">
            <div className="w-6 h-6 rounded mb-3">
              <img src={barchart} alt="Chart Icon" />
            </div>
            <h2 className="text-sm font-bold text-[#080808] font-plus-jakarta-sans">Daily Milk Report</h2>
          </div>
          <div className="bg-white rounded-md border border-gray-300 flex items-center w-14 h-7">
            <img src={ci} className='w-7 h-5' alt="Expand Icon" />
            <p className='ml-1 mt-1'>...</p>
          </div>
        </div>

        <div className="relative h-[350px]">
          <ResponsiveContainer width="100%" height="100%">
            <BarChart data={data} barSize={128} barGap={2}>
              <XAxis dataKey="day" axisLine={false} tickLine={false}  tick={<CustomizedTick />} />
              <YAxis hide />
              <Bar dataKey="litre" shape={<CustomBar />} label={<CustomLabel />} />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </CardBody>
    </div>
  );
};
const CustomizedTick = ({ x, y, payload }) => {
    return (
      <text
        x={x}
        y={y + 15} // Adjust y for vertical position
       fill="#6B7280"
        textAnchor="middle"
        style={{ fontSize: "10px", fontWeight: "bold" }} // Change font size here
      >
        {payload.value}
      </text>
    );
  };

  const CustomBar = (props) => {
    const { x, y, width, height, index, value } = props;
    const currentDayIndex = new Date().getDay(); // Index of the current day
    const dataDayIndex = index;
    const fill = dataDayIndex === currentDayIndex ? "#16A34A" : `url(#gradient${index})`;
    const slopeHeight = 20; 
    const borderRadius = 0;
    const borderRadius2 = 10;
    const borderHeight = 850; 
    // Calculate the y position for the top line
    const topLineY = y; // You can adjust this as needed
    const nextBarValue = index < data.length - 1 ? data[index + 1].litre : value; // Get the next bar's value
    const topLineY1 = y; // Current bar's top
    const slope = 20; // The desired slope value
    const topLineY2 = topLineY1 - slope; 
    
    return (
      <g>
        <defs>
          <linearGradient id={`gradient${index}`} x1="0" y1="0" x2="0" y2="1">
            <stop offset="0%" stopColor="rgba(94.67, 212, 84, 0.55)" />
            <stop offset="100%" stopColor="rgba(255, 255, 255, 0)" />
          </linearGradient>
        </defs>
  
        <path
          d={`
            M ${x + borderRadius},${y}
            L ${x + width - borderRadius},${y + slopeHeight}
            Q ${x + width},${y + slopeHeight} ${x + width},${y + slopeHeight + borderRadius}
            L ${x + width},${y + height - borderRadius}
            Q ${x + width},${y + height} ${x + width - borderRadius},${y + height}
            L ${x + borderRadius},${y + height}
            Q ${x},${y + height} ${x},${y + height - borderRadius}
            L ${x},${y + borderRadius}
            Q ${x},${y} ${x + borderRadius},${y}
            Z
          `}
          fill={fill}
          strokeWidth={1}
        />
  
        {/* Add the green line at the top of the bar */}
        <line
        x1={x}
        y1={topLineY1}
        x2={x + width}
        y2={topLineY1 + slope} // Apply slope to create a descending line
        stroke="#16A34A "
        strokeWidth={1} // Adjust the width as needed
      />

<rect
          x={x}
          // y={ - borderHeight}
          width={width}
          height={borderHeight} // Fixed height for the border
          fill="none" // No fill for the border rectangle
          stroke="#E0E0E0 " // Border color
          strokeWidth={1} // Border width
          rx={borderRadius2} // Horizontal border radius
          ry={borderRadius2} // Vertical border radius
        />
      </g>
    );
  };
  

const CustomLabel = (props) => {
  const { x, y, width, value } = props;
  return (
    <text
      x={x + width / 2}
      y={y - 20}
      fill="#6B7280"
      textAnchor="middle"
      dominantBaseline="middle"
      style={{ fontSize: "12px" }}
    >
      {value}
    </text>
  );
};

export default DailyMilkReport;
