import axios from "axios";


const API = {
    // HOST: "http://13.200.200.33:8080/api/",
    // HOST : "https://api.jaivathottam.com/",
    HOST : process.env.REACT_APP_MAIN_URL,

    GET_ROLE: { url: "api/get_role", method: "GET" },
    LOGIN: { url: "api/auth/signin", method: "POST" },
    CREATE_USER: { url: "api/auth/signup", method: "POST" },
    USER_LIST: { url: "api/user_list", method: "GET" },

    GET_ROLE_PERMISSION: { url: "api/get_modules", method: "GET" },
    ADD_ROLE_AND_PERMISSION: { url: "api/add_role", method: "POST" },
    UPDATE_PASSWORD: { url: "api/change_user_password", method: "POST" },
    UPDATE_ROLE: { url: "api/update_role", method: "POST" },
    UPDATE_STATUS: { url: "api/update_status", method: "POST" },
    UPDATE_USER: { url: "api/update_user", method: "POST" },


    //CATTLE BOARD
    CATTLE_LIST: { url: "api/get_cattles", method: "POST" },
    ADD_CATTLE: { url: "api/add_cattle", method: "POST" },
    GET_CATTLETYPE: { url: "api/get_cattle_type", method: "GET" },
    UPDATE_CATTLE: { url: "api/update_cattle", method: "POST" },
    GET_CATTLE_DETAILS: { url: "api/get_cattle_details/:id", method: "GET" },



    //DIAGNOSIS
    ADD_DIAGNOSIS: { url: "api/add_diagnosis", method: "POST" },
    GET_DIAGNOSIS_LIST: { url: "api/get_diagnosis_list", method: "POST" },
    GET_CATTLE_ID: { url: "api/get_cattle_ids", method: "GET" },




    //BREEDING
    ADD_INSEMINATION: { url: "api/add_insemination", method: "POST" },
    GET_INSEMINATION: { url: "api/get_insemination", method: "POST" },
    MARK_PREGNANCY: { url: "api/mark_as_pregnant", method: "POST" },
    ADD_DELIVERY: { url: "api/add_delivery", method: "POST" },
    UPDATE_INSEMINATION: { url: "api/update_insemination_status", method: "POST" },


    //Breeding Dashboard  
    PENDING_CATTLE_LIST: { url: "api/pending_cattle_list", method: "POST" },
    INSEMINATION_REPORT: { url: "api/status_count", method: "POST" },
   

};


export default API;

// const token = JSON.parse(localStorage.getItem("LoginData"));
// const token = `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6NSwicm9sZSI6IkFkbWluIiwicm9sZV9pZCI6MSwiaWF0IjoxNzMwMDg5NDA0LCJleHAiOjE3MzAxNzU4MDR9.M5frwbppXeh0XFlT-m6px7GsxsA-8knDHV91SCdZe80`;
const token = (localStorage.getItem("usertype"));

const BASE_URL = `${API.HOST}`;

const action = async (endpoint, payload = {}, { headers = {} } = {}) => {
    const { url, method } = endpoint;
    const fullUrl = `${BASE_URL}${url}`;

    const defaultHeaders = {
        ' x-access-token': token,
        ...headers,
    };

    try {
        let response;
        switch (method) {
            case "GET":
                response = await axios.get(fullUrl, { params: payload, headers: defaultHeaders });
                break;
            case "POST":
                response = await axios.post(fullUrl, payload, { headers: defaultHeaders });
                break;
            case "PUT":
                response = await axios.put(fullUrl, payload, { headers: defaultHeaders });
                break;
            case "DELETE":
                response = await axios.delete(fullUrl, { headers: defaultHeaders, payload });
                break;
            default:
                throw new Error(`Unsupported request method: ${method}`);
        }
        return response.data;
    } catch (error) {
        console.error("API Request Error:", error);
        throw error;
    }
};

export { action };


