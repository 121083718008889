import React, { useState } from 'react';
import { FaEdit, FaKey, FaEye } from 'react-icons/fa';
import { Link, useLocation } from 'react-router-dom';
import { ButtonGroup, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Input, Label,  Table, UncontrolledDropdown } from 'reactstrap';
import CommonButton from './Button';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { GoPencil } from 'react-icons/go';
import { MdOutlineLock } from 'react-icons/md';
import { Pagination, Switch } from 'antd';
// import { FaEdit, FaEye, FaLock } from 'react-icons/fa';

function ReactTable({ headings, data, headingcolor = "#FEEAB7", handleAction,handlePassword ,handleToggle,handleEDit,handleUseView }) {
  const location = useLocation();
  const path = location.pathname;
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Set your items per page here


  const [dropdownOpen, setDropdownOpen] = useState(false);

  const capitalizeFirstLetter = (text) => {
    if (!text) return text; // Return the value as is if it's null or empty
    
    // Remove underscores and replace with spaces
    text = text.replace(/_/g, ' ');
  
    // Capitalize the first letter of each word
    return text.replace(/\b\w/g, char => char.toUpperCase());
  };


  const toggle = () => setDropdownOpen(prevState => !prevState);
  


  const totalPages = Math.ceil(data.length / itemsPerPage);

  // Handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Get current items
  const startIndex = (currentPage - 1) * itemsPerPage;
  const currentItems = data.slice(startIndex, startIndex + itemsPerPage);

  return (
    <div className="table-responsive table-card  ">
      <Table className="align-middle table-nowrap mb-0 ">
        <thead>
          <tr>
            {headings.map((heading) => (
              <th
                key={heading.id}
                scope="col"
                style={{
                  color: "#0A0B0A",
                  backgroundColor: headingcolor,
                  fontSize: '12px',
                  fontFamily: 'Plus Jakarta Sans',
                  fontWeight: '700',
                  lineHeight: '19.20px',
                  wordWrap: 'break-word',
                }}
              >
                <div style={{ display: "flex" }}>
                  {heading.icon && (
                    <img
                      src={heading.icon}
                      alt={heading.title || 'Icon'}
                      style={{ width: '16px', height: '16px', marginRight: '8px' }} // Adjust size as needed
                    />
                  )}
                    {heading.title && <span>{capitalizeFirstLetter(heading.title)}</span>}
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody
          style={{
            color: '#4F4D55',
            fontSize: '12px',
            fontFamily: 'Plus Jakarta Sans',
            fontWeight: '700',
            lineHeight: '19.20px',
            wordWrap: 'break-word',
          }}
        >
           {currentItems.length === 0 ? (
            <tr>
              <td colSpan={headings.length} style={{ textAlign: 'center', color: '#A0A0A0', fontFamily:"sans-serif" }}>
                No Data Available
              </td>
            </tr>
          ) : (
          currentItems.map((item, index) => (
            <tr key={index}>
              {headings.map((heading) => {
                console.log(item.Status,"statusss")
                const value = item[heading.value]; // Accessing data using the correct value key

                if (heading.title === 'Action') {
                  return (
                    <td key={heading.id} >
                       {value && value.dots ? (
                        <div>
                          <ButtonGroup>
                            <UncontrolledDropdown>
                              <DropdownToggle tag="button" className="btn  btn-sm" style={{ backgroundColor: "#0EA5E9" }}>
                                <i> <BsThreeDotsVertical style={{ color: "white" }} /></i>
                              </DropdownToggle>
                              <DropdownMenu className="text-[#0A0B0A] text-[9px] font-base break-words">
                                <DropdownItem className=" !flex !items-center text-[#0A0B0A] text-xs font-semibold break-words font-plus-jakarta-sans  "onClick={() => {
                                      console.log('Password change clicked for userdd:', item.userId);
                                      handleEDit([{
                                        userId: item.userId,
                                        Role: item.Role,
                                        UserID: item.UserID,
                                        email: item.email
                                      }]);}}>


                                  <div className="p-1.5 bg-green-600 rounded flex justify-center items-center inline-flex mr-2 font-plus-jakarta-sans">
                                    <GoPencil className="text-[#ffff]" />
                                  </div>Edit Details</DropdownItem>

                                <DropdownItem className="text-[#0A0B0A] text-xs font-semibold break-words !flex !items-center font-plus-jakarta-sans"  onClick={() => {
                                      console.log('Password change clicked for user:', item.userId);
                                      handlePassword(item.userId);
                                  }}>
                                  <div className="p-1.5 bg-green-600 rounded flex justify-center items-center inline-flex mr-2 font-plus-jakarta-sans" ><MdOutlineLock className="text-[#ffff]" /> </div>Change Password</DropdownItem>
                                <DropdownItem className="text-[#0A0B0A] text-xs font-semibold break-words !flex !items-center" onClick={() => {
                                      
                                        handleUseView([{
                                          userId: item.userId,
                                          Role: item.Role,
                                          UserID: item.UserID,
                                          email: item.email,
                                          mobile: item.mobile,
                                          status : item.status,
                                        }]);}}><div className="p-1.5 bg-green-600 rounded flex justify-center items-center inline-flex mr-2 font-plus-jakarta-sans"><FaEye className="text-[#ffff]" /></div> View User Details</DropdownItem>

                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </ButtonGroup>
                        </div>
                      ) : (
                        <CommonButton
                          // style={{ backgroundColor: 'rgba(254, 234, 183, 0.50)', color: "#2B2928", fontSize: "12px" }}
                          // title={item.action}
                          // onClick={() => handleAction(item)}
                          style={{
                            backgroundColor: value === 'Diagnose' ? '#E4626F' :
                              value === 'View Report' ? 'none' :
                                value === 'Vaccinate' ? '#804BF5' :
                                  value === 'Give Medicine' ? '#E4626F' :
                                    value === 'Add Milking' ? '#0EA5E9' :
                                      value === 'View Milk report' ? 'none' :
                                        value === 'Take Delivery' ? '#E4626F' :
                                          value === 'Diagnose' ? '#16A34A' :
                                          value === 'Edit' ? '#ffff':
                                            value === 'View Report' ? '#DBFAE7' :
                                              'rgba(200, 200, 200, 0.50)', // default color
                            color: value === 'Diagnose' ? "#ffff" :
                              value === 'View Milk report' ? '#0EA5E9' :
                              value === 'Edit' ? '#16A34A' :
                                value === 'Take Delivery' ? '#fff' :
                                  value === 'Add Milking' ? '#ffff' :
                                    value === 'Give Medicine' ? "#ffff" :
                                      value === 'View Report' && path.includes('healthMonitoring') ? "#E4626F" :
                                        value === 'View Report' && path.includes('medicationReport') ? "#E4626F" :
                                          value === 'Vaccinate' ? '#FAFAFA' :

                                            (value === 'View Report' && path.includes('vaccination')) ? "#804BF5" :
                                              "#E4626F", // default text color
                            fontSize: "10px",
                            border: value === 'View Report' && path.includes('healthMonitoring') ? "1px #E4626F solid" :
                              value === 'View Report' && path.includes('vaccination') ? "1px #804BF5 solid" :
                                value === 'View Report' && path.includes('medicationReport') ? "1px #E4626F solid" :
                                  value === 'View Milk report' ? '1px #0EA5E9 solid' :
                                  value === 'Edit' ? '1px #16A34A solid' :
                                    value === 'Take Delivery' ? '1px #E4626F solid' :
                                      value === 'View Report' ? '1px #E4626F solid' :
                                        "none",
                          }}
                          title={item.action}
                          onClick={() => handleAction([{
                            id: item.id,
                            role: item.UserRole,
                            permission: item.privilege,
                            InseminationType: item.InseminationType,
                            InseminationDate:item.InseminationDate,
                            ExpectedPregnancy:item.ExpectedPregnancy,
                            CattleID:item.CattleID,
                            ID:item.ID
                            
                          }])}
                        />
                      )}
                    </td>
                  );
                } else if (heading.title === 'Severity' || heading.title === 'Health Status') {
                  const style1 = {
                    fontSize: "12px",
                    
                    fontFamily: 'Plus Jakarta Sans',
                    fontWeight: '700',
                    lineHeight: '19.20px',
                    wordWrap: 'break-word',
                    border: value === "Normal" ? '1px #ACE2FA solid' :
                      value === "Need Care" ? '1px #FEEAB7  solid' :
                        value === "Major" ? '1px #FEEAB7  solid' :
                          value === "Critical" ? '1px #FFA7B0 solid' : "gray",
                    color: value === 'Normal' ? '#0A74A3' :
                      value === "Major" ? '#C98F02' :
                        value === "Need Care" ? '#C98F02 ' :
                          value === "Critical" ? '#E4626F' :
                            "#4F4D55"
                  }
                  return (
                    <td key={heading.id}>
                      <span className="badge" style={style1}>{capitalizeFirstLetter(value) || "--"}</span>
                    </td>
                  );

                  // Other cases remain unchanged
                } else if (heading.title === 'Heat Type') {
                  const badgeStyle = {
                    fontSize: "12px",
                    backgroundColor: value === 'Natural' ? '#DBFAE7' :
                      value === 'Artificial' ? '#FEEAB780' :
                        value === 'Pending' ? 'yellow' :
                          value === 'Natural' ? 'yellow' :
                            'gray',
                    color: value === 'Natural' ? '#16A34A' :
                      value === "Artificial" ? '#2B2928' :
                        'white'
                  };

                  return (
                    <td key={heading.id}>
                      <span className="badge" style={badgeStyle}>{value}</span>
                    </td>
                  );
                } else if (heading.title === 'Status')
                  if (value.toggle) {
                    console.log(`Rendering checkbox for userId: ${item.userId}, Status: ${item.Status.value}`);
                    return (
                      <td key={heading.id}>
                        <div className="">
                          <Switch size='small'   checked={item.Status.value  === true ? "active" : ""} type="checkbox" role="switch" id="SwitchCheck4"   onChange={() => handleToggle(item.userId, item.Status.value)} s  style={{
             
              
            }}
                            />
                          <span className="slider" />
                        </div>
                      </td>
                    );
                  } else {
                    
                    const badgeStyle = {
                      fontSize: "12px",
                      backgroundColor: value === 'Milking' ? '#DBFAE7' :
                        value === 'Pregnant' ? '#DBFAE7' :
                          value === 'Dry Cattle' ? 'yellow' :
                            // value === 'Inseminated' ? '#FEEAB7' :
                            value === 'inseminated' && path.includes('breeding') ? '#fff' :
                              value === 'Heated' ? 'none' :
                                value === 're_inseminated' ? 'none' :
                                  'none',

                      color: value === 'Milking' ? '#0A74A3' :
                        value === 'Pregnant' ? '#16A34A' :
                          value === 'Dry Cattle' ? '#8C1823' :
                            // value === 'Inseminated' ? '#C98F02' :
                            value === 'Heated' ? '#C98F02' :
                              value === 'inseminated' && path.includes('breeding') ? '#0A74A3' :
                                value === 're_inseminated' ? '#E4626F' :
                                value === 'failed' ? '#E4626F' :
                                  value === 'Pregnant' ? '#16A34A' :
                                  value === 'diagnosed'?'#0A74A3' :
                                    'none',
                      border: value === 'inseminated' && path.includes('breeding') ? '1px #ACE2FA solid ' :
                        value === 're_inseminated' ? '1px #FFA7B0 solid' :
                          value === 'Heated' ? '1px #FEEAB7 solid ' :
                            value === 'Pregnant' ? '1px #70EC9E solid' :
                              'none',
                    };

                    return (
                      <td key={heading.id}>
                        <span className="badge" style={{ ...badgeStyle, alignItems: 'center' }}>
                        {capitalizeFirstLetter(value) || "--"}
                        </span>
                      </td>
                    );
                  } else {
                  return <td key={heading.id} style={{
                    color: '#4F4D55',
                    fontSize: '12px',
                    fontFamily: 'Plus Jakarta Sans',
                    fontWeight: '700',
                    lineHeight: '19.20px',
                    wordWrap: 'break-word',
                  }}>{capitalizeFirstLetter(value)|| "--"} </td>; // Default case
                }
              })}
            </tr>

          )))} 
        </tbody>
      </Table>
      {data.length > itemsPerPage && (
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px' }}>
        <Pagination
          current={currentPage}
          total={data.length}
          pageSize={itemsPerPage}
          onChange={handlePageChange}
          showSizeChanger={false}
          style={{ padding: '0.5rem', fontFamily: "sans-serif", color:"#A0A0A0" }}
        />
      </div>
      )}
    </div>
  );
}

export default ReactTable;
