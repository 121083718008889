import { Calendar } from 'antd';
import React, { useState } from 'react';
import { CardBody } from 'reactstrap';


function CustomCalendar() {
  const [value, setValue] = useState(new Date());

  const events = [
    { date: new Date(2023, 10, 7), type: 'Delivery' },
    { date: new Date(2023, 10, 1), type: 'Vaccination' },
    { date: new Date(2023, 10, 15), type: 'Milk Checking' },
  ];

  const tileClassName = ({ date, view }) => {
    if (view === 'month') {
      const event = events.find(e => date.toDateString() === e.date.toDateString());
      if (event) {
        switch (event.type) {
          case 'Delivery':
            return 'bg-yellow-500 text-white rounded-full m-1 calendar-event animate-pulse';
          case 'Vaccination':
            return 'border-2 border-green-500 rounded-full m-1 calendar-event';
          case 'Milk Checking':
            return 'border-2 border-blue-500 rounded-full m-1 calendar-event';
          default:
            return '';
        }
      }
    }
    return '';
  };

  return (
    <div>
      <React.Fragment>
        <div className="2xl:p-6 p-2 bg-white rounded-xl border border-gray-300">
          <CardBody className="p-0 pb-2">
            <div style={{ height: '420px', overflowY: 'auto' }}>
              <Calendar
                className="custom-calendar"
                // Add more props as needed
              />
            </div>
          </CardBody>
        </div>
      </React.Fragment>
    </div>
  );
}

export default CustomCalendar;
